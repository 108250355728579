import * as Sentry from '@sentry/browser'
import { IgniticSettingsContext } from 'ignitic/dist/context/ignitic-settings-context'
import { ScreenContextProvider } from 'ignitic/dist/context/screen-context-provider'
import { Normalize } from 'ignitic/dist/styles/normalize'
import { uuid } from 'itils/dist/misc/uuid'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { version } from '../../package.json'
import { App } from './app/app'
import { AppErrorBoundary } from './app/app-error-boundary'

declare global {
  interface HTMLElement {
    inert: boolean
  }
  namespace NodeJS {
    interface ProcessEnv {
      NODE_ENV: 'production' | 'development' | 'test'
      FIREBASE_API_KEY: string
      FIREBASE_AUTH_DOMAIN: string
      FIREBASE_DATABASE_URL: string
      FIREBASE_PROJECT_ID: string
      FIREBASE_STORAGE_BUCKET: string
      FIREBASE_MESSAGING_SENDER_ID: string
      SENTRY_DSN?: string
    }
  }
}
if (process.env.SENTRY_DSN !== undefined) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.NODE_ENV,
    release: version,
  })
}
console.log({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.NODE_ENV,
})

const instanceId = uuid()
const container = document.getElementById('root') as HTMLElement

ReactDOM.render(
  <AppErrorBoundary instanceId={instanceId}>
    <Normalize />
    {/* <React.StrictMode> */}
    <IgniticSettingsContext.Provider value={{ container }}>
      <ScreenContextProvider>
        <App instanceId={instanceId} />
      </ScreenContextProvider>
    </IgniticSettingsContext.Provider>
    {/* </React.StrictMode> */}
  </AppErrorBoundary>,
  container
)
