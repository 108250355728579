import { User } from 'firebase/auth'
import { doc, serverTimestamp, Timestamp, writeBatch } from 'firebase/firestore'
import { firestore } from '../..'
import {
  CareOrderType,
  createCareOrderId,
  NewCareOrderDocument,
  UpdateCareOrderDocument,
} from '../../../model/care-order'
import { chunks } from '../../../utils/array'
import { Omit } from '../../../utils/type'
import { Collections } from '../collections'
import { dayId } from '../day/day-id'
import { FIRESTORE_MAX_BATCH_SIZE } from '../utils/constants'

export type UpdateCareOrderItem = {
  type: 'update'
  id: string
  update: Omit<UpdateCareOrderDocument, 'lastModified'>
}
export type AddCareOrderItem = {
  type: 'add'
  add: {
    type: CareOrderType
    receiver: {
      id: string
      name: string
      deliveryLocation: string
      kennitala: string
    }
    customer: {
      id: string
      name: string
    }
    day: Date
    delivery: { type: 'not set' } | { type: 'route'; id: string; name: string }
    portions: number
    careProviderNote: string
    customerNote: string
    foodType: string
    foodRestrictions: string[]
  }
}

export async function batchAddOrUpdateCareOrders(
  items: Array<UpdateCareOrderItem | AddCareOrderItem>,
  user: User | 'system'
) {
  await Promise.all(
    chunks(items, FIRESTORE_MAX_BATCH_SIZE).map(async (chunk) => {
      const batch = writeBatch(firestore)
      chunk.forEach((i) => {
        switch (i.type) {
          case 'update': {
            const update: UpdateCareOrderDocument = {
              ...i.update,
              lastModified: {
                timestamp: serverTimestamp() as any,
                by:
                  user == 'system'
                    ? { type: 'system' }
                    : {
                        type: 'user',
                        uid: user.uid,
                        email: user.email as string,
                      },
              },
            }
            batch.update(doc(firestore, Collections.CareOrders, i.id), update)
            break
          }
          case 'add': {
            const { ...rest } = i.add
            const newOrder: NewCareOrderDocument = {
              ...rest,
              day: Timestamp.fromDate(rest.day),
              dayId: dayId(rest.day),
              deliveryStatus: { type: 'not set' },
              created: {
                timestamp: serverTimestamp() as any,
                by:
                  user == 'system'
                    ? { type: 'system' }
                    : {
                        type: 'user',
                        uid: user.uid,
                        email: user.email as string,
                      },
              },
            }
            batch.set(
              doc(
                firestore,
                Collections.CareOrders,
                createCareOrderId(newOrder)
              ),
              newOrder
            )
            break
          }
        }
      })
      await batch.commit()
    })
  )
}
