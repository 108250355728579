import { Day, DayDocument } from '../../../model/day/day'
import { DocSnapshot } from '../utils/types'

export function parseDaySnapsnot(doc: DocSnapshot): Day {
  const data = doc.data() as DayDocument
  return {
    ...data,
    id: doc.id,
  }
}
