import { Customer, CustomerDocument } from '../../../model/customer'
import { DocSnapshot } from '../utils/types'

export function parseCustomerSnapshot(doc: DocSnapshot): Customer {
  const data = doc.data() as CustomerDocument
  return {
    ...data,
    id: doc.id,
  }
}
