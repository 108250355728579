import { CareReceiverStatus, DurationStatus } from '.'

export function isDurationStatus(
  status: CareReceiverStatus
): status is DurationStatus {
  return (
    status.type === 'repeat' ||
    status.type === 'bed rest admission' ||
    status.type === 'hospital' ||
    status.type === 'on pause'
  )
}
