import {
  CustomRouteOrdering,
  CustomRouteOrderingDocument,
} from '../../../model/custom-route-ordering'
import { DocSnapshot } from '../utils/types'
export function parseCustomRouteOrderingSnapshot(
  doc: DocSnapshot
): CustomRouteOrdering {
  const data = doc.data() as CustomRouteOrderingDocument
  return {
    ...data,
    id: doc.id,
  }
}
