import { getNotInOrderRouteId } from '../../client/admin/routes/overview/routes-papers-page/get-not-in-order-route-id'
import { CareOrder } from '../../model/care-order'
import { deliveryId, deliveryName } from '../../model/care-order/delivery-id'
import { CareReceiverId } from '../../model/care-receiver'
import { CustomRouteOrdering } from '../../model/custom-route-ordering'
import { OrderProperty, Route } from '../../model/route'
import { immutablePush } from '../array'

export function updateCustomRouteOrder(
  customRouteOrder: CustomRouteOrdering,
  orders: CareOrder[],
  routes: Route[],
  orderProperty: OrderProperty
) {
  const result = { ...customRouteOrder.orderings }
  const rIdsInOrdering = new Set<CareReceiverId>()
  Object.values(customRouteOrder.orderings).forEach((i) => {
    i.order.forEach((o) => {
      rIdsInOrdering.add(o)
    })
  })
  orders
    .filter((o) => o.portions > 0)
    .forEach((i) => {
      const routeId = deliveryId(i.delivery)
      const routeName = deliveryName(i.delivery)
      const notInOrderRouteId = getNotInOrderRouteId(routeId)

      /**
       * If custom order doesn't have entry for this route
       * we have to build it.
       */
      if (result[routeId] == undefined) {
        const ordersInThisRoute = orders
          .filter((o) => o.portions > 0)
          .filter(
            (o) =>
              deliveryId(o.delivery) == routeId &&
              !rIdsInOrdering.has(o.receiver.id)
          )
        const route = routes.find((r) => r.id == routeId)
        const routeOrder = route ? route[orderProperty] : []
        type OrderIndex = number
        const receiverOrderIndex = new Map<CareReceiverId, OrderIndex>()
        routeOrder.forEach((receiverId, index) => {
          receiverOrderIndex.set(receiverId, index)
        })
        ordersInThisRoute
          .filter((o) => receiverOrderIndex.has(o.receiver.id))
          .forEach((o) => {
            rIdsInOrdering.add(o.receiver.id)
          })
        result[routeId] = {
          type: routeId == '-' ? '-' : 'route order',
          routeId,
          routeName,
          order: ordersInThisRoute
            .filter(
              (o) => routeId == '-' || receiverOrderIndex.has(o.receiver.id)
            )
            .sort((a, b) => {
              const aIndex = receiverOrderIndex.get(a.receiver.id)
              const bIndex = receiverOrderIndex.get(b.receiver.id)
              return (
                (aIndex == undefined ? Number.MAX_VALUE : aIndex) -
                (bIndex == undefined ? Number.MAX_VALUE : bIndex)
              )
            })
            .map((o) => o.receiver.id),
        }
      } else if (result[notInOrderRouteId] == undefined) {
        const ordersInThisRoute = orders
          .filter((o) => o.portions > 0)
          .filter(
            (o) =>
              deliveryId(o.delivery) == routeId &&
              !rIdsInOrdering.has(o.receiver.id)
          )
        const route = routes.find((r) => r.id == routeId)
        const routeDefaultOrder = route ? route[orderProperty] : []
        type OrderIndex = number
        const receiverOrderIndex = new Map<CareReceiverId, OrderIndex>()
        routeDefaultOrder.forEach((receiverId, index) => {
          receiverOrderIndex.set(receiverId, index)
        })
        const notInOrder = ordersInThisRoute
          .filter((o) => receiverOrderIndex.has(o.receiver.id))
          .map((o) => o.receiver.id)
        if (notInOrder.length > 0) {
          result[notInOrderRouteId] = {
            type: 'route not in order',
            routeId,
            routeName: `${routeName} - Ekki í röð`,
            order: notInOrder,
          }
        }
      } else if (!rIdsInOrdering.has(i.receiver.id)) {
        const notInRouteOrder = result[notInOrderRouteId]
        if (!notInRouteOrder.order.includes(i.receiver.id)) {
          notInRouteOrder.order = immutablePush(
            notInRouteOrder.order,
            i.receiver.id
          )
        }
      }
    })
  return result
}
