import { User } from 'firebase/auth'
import { UserClaims } from '../../model/user-meta/user-claims'
import { b64DecodeUnicode } from '../../utils/b64-decode-unicode'

export async function getClaims(
  user: User | null,
  options: { forceRefresh: boolean } = { forceRefresh: false }
) {
  if (user === null) {
    return undefined
  }
  if (options.forceRefresh) {
    console.info('force refreshing claims')
  }
  return user.getIdToken(options.forceRefresh).then((token) => {
    return JSON.parse(b64DecodeUnicode(token.split('.')[1])) as UserClaims
  })
}
