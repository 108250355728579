import { SortOrder } from '../model/types/sort-order'

export function getSortDirection<T>(sortOrder: SortOrder<T>, type: T) {
  if (sortOrder.type == type) {
    return sortOrder.direction
  }
  return false
}

export function createOnSortButtonClickHandler<T>(
  sortOrder: SortOrder<T>,
  setSortOrder: (so: SortOrder<T>) => void,
  type: T
) {
  return () => {
    if (sortOrder.type === type) {
      setSortOrder(
        sortOrder.direction === 'asc'
          ? { type, direction: 'desc', value: -1 }
          : {
              type,
              direction: 'asc',
              value: 1,
            }
      )
    } else {
      setSortOrder({ type: type, direction: 'asc', value: 1 })
    }
  }
}
