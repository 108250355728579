export function deliveryId(
  delivery: { type: 'not set' } | { type: 'route'; id: string; name: string }
) {
  return delivery.type == 'route' ? delivery.id : '-'
}

export function deliveryName(
  delivery: { type: 'not set' } | { type: 'route'; id: string; name: string }
) {
  return delivery.type == 'not set' ? 'Ekki í bíl' : delivery.name
}
