export function isKennitala(kennitala: string) {
  // if value is not the length of 10 or not only numbers
  if (kennitala.length !== 10 || kennitala.match(/^[0-9]+$/) === null) {
    return false
  }

  // Modulus 11 check the 9th number
  const sequence = [3, 2, 7, 6, 5, 4, 3, 2]

  let sum = 0
  for (let i = 0; i < 8; i += 1) {
    sum += +kennitala[i] * sequence[i]
  }

  const modulus11 = sum % 11
  const calculatedNumber = modulus11 > 0 ? 11 - modulus11 : 0

  return calculatedNumber === +kennitala[8]
}
