import { addDoc, collection } from 'firebase/firestore'
import { firestore } from '../..'
import { ErrorBoundaryLogDocument } from '../../../model/logs/error-boundary-log'
import { RouteLogDocument } from '../../../model/logs/route-log'
import { Collections } from '../collections'

export function addRouteLog(doc: RouteLogDocument) {
  return addDoc(collection(firestore, Collections.Logs), doc)
}

export function addErrorBoundaryLog(doc: ErrorBoundaryLogDocument) {
  return addDoc(collection(firestore, Collections.Logs), doc)
}
