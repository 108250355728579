import { UserMeta, UserMetaDocument } from '../../../model/user-meta'
import { DocSnapshot } from '../utils/types'

export function parseUserMetaSnapshot(value: DocSnapshot): UserMeta {
  const data = value.data() as UserMetaDocument
  return {
    ...data,
    id: value.id,
  }
}
