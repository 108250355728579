import {
  endOfWeek,
  format,
  formatRelative,
  getDay,
  startOfWeek,
} from 'date-fns'
import { is as dateFnsIs } from 'date-fns/locale'

const formatRelativeLocale = {
  lastWeek: "d'.' LLL y",
  yesterday: "'í gær kl.' p",
  today: "'í dag kl.' p",
  tomorrow: "'á morgun kl.' p",
  nextWeek: "d'.' LLL y",
  other: "d'.' LLL y",
} as any

function myFormatRelative(token: any, date: any, baseDate: any, options: any) {
  return formatRelativeLocale[token]
}

export const is = {
  ...dateFnsIs,
  formatRelative: myFormatRelative,
  options: { ...dateFnsIs.options, firstWeekContainsDate: 1 },
} as Locale

export function formatRelativeNowIs(date: Date) {
  return formatRelative(date, new Date(), { locale: is })
}
export function formatIs(date: Date, frmt: string) {
  return format(date, frmt, { locale: is })
}
export function startOfWeekIs(date: Date) {
  return startOfWeek(date, { locale: is })
}
export function endOfWeekIs(date: Date) {
  return endOfWeek(date, { locale: is })
}
export function getDayOfWeekIs(date: Date): 0 | 1 | 2 | 3 | 4 | 5 | 6 {
  const realDayOfWeek = getDay(date)
  switch (realDayOfWeek) {
    case 0:
      return 6
    default:
      return (realDayOfWeek - 1) as 0 | 1 | 2 | 3 | 4 | 5
  }
}
