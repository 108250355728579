import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import * as React from 'react'

import {
  CareReceiverStatus,
  RedDaysValue,
  RepeatStatus,
} from '../../../model/care-receiver-status'
import { redDays } from '../../../model/care-receiver-status/values'
import { SelectFieldBaseProps } from '../../select-field'
import { SelectStringField } from '../../select-string-field'

type Props = SelectFieldBaseProps & {
  status: RepeatStatus
  setStatus: (status: CareReceiverStatus) => void
}

export function SelectRedDaysType({ setStatus, status, ...rest }: Props) {
  return (
    <React.Fragment>
      <SelectStringField
        {...rest}
        value={status.redDays.type}
        onChange={(value) => {
          switch (value as RedDaysValue['type']) {
            case 'no':
              setStatus({
                ...status,
                redDays: { type: 'no' },
              })
              break
            case 'yes':
              setStatus({
                ...status,
                redDays: { type: 'yes' },
              })
              break
            case 'all':
              setStatus({
                ...status,
                redDays: { type: 'all', portions: 1 },
              })
              break
            default:
              break
          }
        }}
      >
        {redDays.map((r) => (
          <MenuItem key={r.type} value={r.type}>
            {r.label}
          </MenuItem>
        ))}
      </SelectStringField>
      {status.redDays.type === 'all' && (
        <TextField
          label="Skamtar á rauðum dögum"
          value={status.redDays.portions}
          onChange={(event) => {
            const portions = parseInt(event.target.value, 10)
            if (isNaN(portions) || status.type !== 'repeat' || portions < 0) {
              return
            }
            setStatus({
              ...status,
              redDays: {
                type: 'all',
                portions,
              },
            })
          }}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          helperText=" "
        />
      )}
    </React.Fragment>
  )
}
