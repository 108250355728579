import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import { User } from 'firebase/auth'
import { serverTimestamp } from 'firebase/firestore'
import { Button } from 'ignitic/dist/core/button'
import { P } from 'ignitic/dist/core/text'
import { useUUID } from 'ignitic/dist/hooks/use-uuid'
import { display } from 'ignitic/dist/styles/display'
import { flex } from 'ignitic/dist/styles/flex'
import { cn } from 'itils/dist/misc/cn'
import isEqual from 'lodash/isEqual'
import * as React from 'react'
import { updateCareReceiver } from '../../firebase/firestore/care-receivers/update-care-receiver'
import { CareReceiver } from '../../model/care-receiver'
import { totalPortions } from '../../model/care-receiver-status'
import { defaultRepeatStatus } from '../../model/care-receiver-status/default'
import { allUpdateToStatuses } from '../../model/care-receiver-status/values'
import { Day } from '../../model/day/day'
import { formatIs } from '../../utils/date-fns'
import { StatusFormGroup } from './status-form-group'

export type UpdateCareReceiverStatusDialogOpen =
  | {
      type: 'open'
      receiver: CareReceiver
    }
  | { type: 'closed' }

type Props = {
  open: UpdateCareReceiverStatusDialogOpen
  onClose: () => void
  id: string
  user: User
  presentAndFutureDays: Day[]
}

export function UpdateCareReceiverStatusDialog({
  open,
  onClose,
  id,
  user,
  presentAndFutureDays,
}: Props) {
  const titleId = useUUID()
  const [status, setStatus] = React.useState(defaultRepeatStatus)

  React.useEffect(() => {
    if (open.type == 'open') {
      setStatus(open.receiver.status)
    }
  }, [open])

  const notOpenPresentAndFutureDays = presentAndFutureDays.filter(
    (i) => i.careDayStatus.type != 'open'
  )

  function onDialogClose() {
    onClose()
    resetState()
  }

  function resetState() {
    setStatus(defaultRepeatStatus)
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (open.type == 'closed') {
      return
    }
    const update: Parameters<typeof updateCareReceiver>[0]['update'] = {
      status,
    }
    if (open.receiver.status.type == 'repeat') {
      update.lastRepeatStatus = {
        status: { ...open.receiver.status, duration: { type: 'indefinite' } },
        when: serverTimestamp() as any,
      }
    }
    updateCareReceiver({
      id: open.receiver.id,
      update,
      user,
    }).catch((err: any) => console.error('updateCareReceiver error', err))
    onClose()
  }

  return (
    <Dialog
      open={open.type == 'open'}
      onClose={onDialogClose}
      id={id}
      aria-labelledby={titleId}
    >
      {open.type == 'open' && (
        <React.Fragment>
          <DialogTitle style={{ paddingBottom: 0 }} id={titleId}>
            Uppfæra stöðu {open.receiver.name}
          </DialogTitle>
          {notOpenPresentAndFutureDays.length > 0 && (
            <DialogContent
              className={cn(display.flex, flex.row, flex.spacingMd)}
              style={{ paddingTop: '1rem', overflowY: 'visible' }}
            >
              <InfoOutlined color="primary" />
              <div className={cn(display.flex, flex.col)}>
                <P>
                  Eftirfarandi dögum hefur verið lokað fyrir breytingum á
                  pöntunum og munu því breytingar sem gerðar eru hér á stöðu
                  ekki hafa áhrif á þá:
                </P>
                <ul>
                  {notOpenPresentAndFutureDays.map((d) => (
                    <li key={d.id}>
                      <P>{formatIs(d.day.toDate(), 'PP')}</P>
                    </li>
                  ))}
                </ul>
              </div>
            </DialogContent>
          )}
          <form noValidate autoComplete="off" onSubmit={onSubmit}>
            <DialogContent
              style={{ minWidth: 585 }}
              className={cn(display.flex, flex.col, flex.spacingSm)}
            >
              <StatusFormGroup
                careReceiver={open.receiver}
                status={status}
                setStatus={setStatus}
                statuses={allUpdateToStatuses
                  .filter((s) => {
                    if (open.receiver.status.type != 'repeat') {
                      return s.type != 'repeat'
                    }
                    return true
                  })
                  .filter((s) => {
                    if (!open.receiver.lastRepeatStatus) {
                      return s.type != 'repeat last'
                    }
                    return true
                  })
                  .filter((s) => {
                    if (status.type == 'repeat') {
                      if (
                        isEqual(status, open.receiver.status) ||
                        (open.receiver.lastRepeatStatus &&
                          isEqual(
                            status,
                            open.receiver.lastRepeatStatus.status
                          )) ||
                        totalPortions(status) == 0
                      ) {
                        return s.type != 'repeat mod'
                      }
                    }
                    return true
                  })}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={onDialogClose}
                type="button"
                variant="outlined"
                color="secondary"
              >
                Hætta við
              </Button>
              <Button type="submit" variant="outlined" color="primary">
                Vista
              </Button>
            </DialogActions>
          </form>
        </React.Fragment>
      )}
    </Dialog>
  )
}
