import MenuItem from '@material-ui/core/MenuItem'
import * as React from 'react'
import { CareReceiver } from '../../../model/care-receiver'
import { CareReceiverStatus } from '../../../model/care-receiver-status'
import {
  defaultBedRestAdmissionStatus,
  defaultDeceacedStatus,
  defaultHospitalStatus,
  defaultOnPauseStatus,
  defaultQuitStatus,
  defaultRepeatStatus,
  defaultUnknownStatus,
} from '../../../model/care-receiver-status/default'
import { CareReceiverStatuses } from '../../../model/care-receiver-status/values'
import { formatIs } from '../../../utils/date-fns'
import { SelectFieldBaseProps } from '../../select-field'
import { SelectStringField } from '../../select-string-field'

type Props = SelectFieldBaseProps & {
  careReceiver?: CareReceiver
  value:
    | CareReceiverStatus['type']
    | 'repeat empty'
    | 'repeat last'
    | 'repeat mod'
  statuses: CareReceiverStatuses<'repeat empty' | 'repeat last' | 'repeat mod'>
  setStatus: (status: CareReceiverStatus) => void
}

export function SelectStatusType({
  statuses,
  setStatus,
  careReceiver,
  ...rest
}: Props) {
  return (
    <SelectStringField
      {...rest}
      onChange={(value) => {
        switch (
          value as
            | CareReceiverStatus['type']
            | 'repeat empty'
            | 'repeat last'
            | 'repeat mod'
        ) {
          case 'repeat last':
            if (careReceiver && careReceiver.lastRepeatStatus) {
              setStatus(careReceiver.lastRepeatStatus.status)
            }
            break
          case 'repeat':
            if (careReceiver) {
              setStatus(careReceiver.status)
            } else {
              setStatus(defaultRepeatStatus)
            }
            break
          case 'repeat mod':
          case 'repeat empty':
            setStatus(defaultRepeatStatus)
            break
          case 'hospital':
            setStatus(defaultHospitalStatus)
            break
          case 'bed rest admission':
            setStatus(defaultBedRestAdmissionStatus)
            break
          case 'on pause':
            setStatus(defaultOnPauseStatus)
            break
          case 'unknown':
            setStatus(defaultUnknownStatus)
            break
          case 'deceased':
            setStatus(defaultDeceacedStatus)
            break
          case 'quit':
            setStatus(defaultQuitStatus)
            break
          default:
            break
        }
      }}
    >
      {statuses.map((s) => (
        <MenuItem key={s.type} value={s.type}>
          {s.label}
          {s.type == 'repeat last' &&
            careReceiver &&
            careReceiver.lastRepeatStatus &&
            careReceiver.lastRepeatStatus.when &&
            ` ${formatIs(careReceiver.lastRepeatStatus.when.toDate(), 'PP')}`}
        </MenuItem>
      ))}
    </SelectStringField>
  )
}
