import { User } from 'firebase/auth'
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { firestore } from '../../firebase'
import { Collections } from '../../firebase/firestore/collections'
import { Omit } from '../../utils/type'
import { DayDocumentUpdate } from './day'

type UpdateDayArg = {
  id: string
  update: Omit<DayDocumentUpdate, 'lastModified'>
  user: User
}

export function updateDay({ id, update, user }: UpdateDayArg) {
  const updateDocument: DayDocumentUpdate = {
    ...update,
    lastModified: {
      timestamp: serverTimestamp() as any,
      by: {
        type: 'user',
        uid: user.uid,
        email: user.email as string,
      },
    },
  }
  return updateDoc(doc(firestore, Collections.Days, id), updateDocument)
}
