import Card from '@material-ui/core/Card'
import ArrowDownwardOutlined from '@material-ui/icons/ArrowDownwardOutlined'
import addDays from 'date-fns/addDays'
import { H4, Span } from 'ignitic/dist/core/text'
import { display } from 'ignitic/dist/styles/display'
import { flex } from 'ignitic/dist/styles/flex'
import { cn } from 'itils/dist/misc/cn'
import isEqual from 'lodash/isEqual'
import * as React from 'react'
import { CareReceiver } from '../../../model/care-receiver'
import {
  CareReceiverStatus,
  RepeatStatus,
  totalPortions,
} from '../../../model/care-receiver-status'
import { isDurationStatus } from '../../../model/care-receiver-status/is-duration-status'
import {
  allUpdateToStatuses,
  CareReceiverStatuses,
} from '../../../model/care-receiver-status/values'
import { formatIs } from '../../../utils/date-fns'
import { Overwrite } from '../../../utils/type'
import { PortionsTextFields } from './portions-text-fields'
import { SelectDuration } from './select-duration'
import { SelectRedDaysType } from './select-red-days-type'
import { SelectStatusType } from './select-status-type'

type Props = {
  careReceiver?: CareReceiver
  status: CareReceiverStatus
  statuses: CareReceiverStatuses<'repeat empty' | 'repeat last' | 'repeat mod'>
  setStatus: (status: CareReceiverStatus) => void
  minDate?: Date
}

function isEqualStatusExceptDuration(a: RepeatStatus, b: CareReceiverStatus) {
  if (a.type != b.type) {
    return false
  }
  const aCmp: Overwrite<RepeatStatus, { duration?: RepeatStatus['duration'] }> =
    { ...a }
  delete aCmp.duration
  const bCmp: Overwrite<RepeatStatus, { duration?: RepeatStatus['duration'] }> =
    { ...b }
  delete bCmp.duration
  return isEqual(aCmp, bCmp)
}

export function StatusFormGroup(props: Props) {
  console.log('StatusFormGroup', { status: props.status })
  return (
    <React.Fragment>
      <Card
        className={cn(display.flex, flex.col, flex.spacingSm)}
        style={{
          marginLeft: -12,
          marginRight: -12,
          padding: 12,
          background: 'hsla(0, 0%, 0%, 0.01)',
        }}
      >
        <SelectStatusType
          label="Staða"
          required
          careReceiver={props.careReceiver}
          value={
            props.status.type == 'repeat'
              ? props.careReceiver == undefined
                ? 'repeat mod'
                : isEqualStatusExceptDuration(
                    props.status,
                    props.careReceiver.status
                  )
                ? 'repeat'
                : props.careReceiver.lastRepeatStatus &&
                  isEqualStatusExceptDuration(
                    props.careReceiver.lastRepeatStatus.status,
                    props.status
                  )
                ? 'repeat last'
                : totalPortions(props.status) == 0
                ? 'repeat empty'
                : 'repeat mod'
              : props.status.type
          }
          statuses={props.statuses}
          setStatus={props.setStatus}
        />
        {props.status.type === 'repeat' && (
          <React.Fragment>
            <H4 variant="p.lead">Skamtar</H4>
            <PortionsTextFields
              status={props.status}
              setStatus={props.setStatus}
            />
            <SelectRedDaysType
              required
              label="Rauðir dagar"
              status={props.status}
              setStatus={props.setStatus}
            />
          </React.Fragment>
        )}
        {isDurationStatus(props.status) && (
          <SelectDuration
            careReceiver={props.careReceiver}
            label="Tímalengd"
            untilLabel="Til og með"
            required
            status={props.status}
            setStatus={props.setStatus}
            minDate={props.minDate}
          />
        )}
      </Card>
      {isDurationStatus(props.status) &&
        props.status.duration.type === 'until' && (
          <React.Fragment>
            <div
              className={cn(
                display.flex,
                flex.col,
                flex.justifyCenter,
                flex.alignCenter
              )}
            >
              <ArrowDownwardOutlined fontSize="large" />
              <Span variant="p.lead">
                Frá og með{' '}
                {formatIs(
                  addDays(props.status.duration.until.toDate(), 1),
                  'PP'
                )}
              </Span>
            </div>
            <StatusFormGroup
              careReceiver={props.careReceiver}
              minDate={addDays(props.status.duration.until.toDate(), 1)}
              status={props.status.duration.next}
              statuses={allUpdateToStatuses
                .filter((s) => {
                  if (
                    !props.careReceiver ||
                    props.careReceiver.status.type != 'repeat'
                  ) {
                    return s.type != 'repeat'
                  }
                  return true
                })
                .filter((s) => {
                  if (
                    !props.careReceiver ||
                    !props.careReceiver.lastRepeatStatus
                  ) {
                    return s.type != 'repeat last'
                  }
                  return true
                })
                .filter((s) => {
                  if (
                    props.careReceiver &&
                    isDurationStatus(props.status) &&
                    props.status.duration.type === 'until' &&
                    props.status.duration.next.type == 'repeat'
                  ) {
                    if (
                      isEqual(
                        props.status.duration.next,
                        props.careReceiver.status
                      ) ||
                      (props.careReceiver.lastRepeatStatus &&
                        isEqual(
                          props.status.duration.next,
                          props.careReceiver.lastRepeatStatus.status
                        )) ||
                      totalPortions(props.status.duration.next) == 0
                    ) {
                      return s.type != 'repeat mod'
                    }
                  }
                  return true
                })}
              setStatus={(next) => {
                if (
                  !isDurationStatus(props.status) ||
                  props.status.duration.type !== 'until'
                ) {
                  return
                }
                props.setStatus({
                  ...props.status,
                  duration: {
                    ...props.status.duration,
                    next,
                  },
                })
              }}
            />
          </React.Fragment>
        )}
    </React.Fragment>
  )
}
