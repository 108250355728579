import { assertNever } from 'itils/dist/ts/assert-never'

import { RedDaysValue } from '.'

export function redDaysValueToText(v: RedDaysValue): string {
  switch (v.type) {
    case 'all':
      return `Alltaf ${v.portions}`
    case 'yes':
      return 'Eftir plani'
    case 'no':
      return 'Aldrei'
    default:
      assertNever(v)
      return ''
  }
}
