import {
  CareReceiver,
  CareReceiverDocument,
} from '../../../model/care-receiver/care-receiver'
import { DocSnapshot } from '../utils/types'

export function parseCareReceiverSnapshot(doc: DocSnapshot): CareReceiver {
  const data = doc.data() as CareReceiverDocument
  try {
    return parseCareReceiverDocument(data, doc.id)
  } catch (err) {
    throw {
      message: 'Unable to parse care receiver document',
      err,
      id: doc.id,
    }
  }
}

export function parseCareReceiverDocument(
  data: CareReceiverDocument,
  id: string
): CareReceiver {
  return {
    ...data,
    id,
  }
}
