import format from 'date-fns/format'
import isSameDay from 'date-fns/isSameDay'
import isValid from 'date-fns/isValid'
import isWithinInterval from 'date-fns/isWithinInterval'
import { IconButton } from 'ignitic/dist/core/icon-button'
import { cn } from 'itils/dist/misc/cn'
import DatePicker from 'material-ui-pickers/DatePicker'
import * as React from 'react'

import { endOfWeekIs, formatIs, startOfWeekIs } from '../../utils/date-fns'
import * as css from './week-picker.css'

type Props = {
  label: string
  value: Date
  setValue: (value: Date) => void
}

export function WeekPicker({ label, value, setValue }: Props) {
  return (
    <DatePicker
      style={{ width: '11.2rem' }}
      label={label}
      value={value}
      cancelLabel="Hætta við"
      okLabel="Velja"
      autoOk
      onChange={(d) => setValue(startOfWeekIs(d))}
      renderDay={(date, selectedDate, dayInCurrentMonth) => {
        const start = startOfWeekIs(selectedDate)
        const end = endOfWeekIs(selectedDate)
        const dayIsBetween = isWithinInterval(date, { start, end })
        const isFirstDay = isSameDay(date, start)
        const isLastDay = isSameDay(date, end)

        return (
          <div
            className={cn({
              [css.highlight]: dayIsBetween,
              [css.firstHighlight]: isFirstDay,
              [css.endHighlight]: isLastDay,
            })}
          >
            <IconButton
              className={cn(css.day, {
                [css.nonCurrentMonthDay]: !dayInCurrentMonth,
                [css.highlightNonCurrentMonthDay]:
                  !dayInCurrentMonth && dayIsBetween,
              })}
              aria-label={format(date, 'd')}
              onClick={() => {}}
            >
              <span className={cn({ [css.highlight]: dayIsBetween })}>
                {format(date, 'd')}
              </span>
            </IconButton>
          </div>
        )
      }}
      labelFunc={(date, invalidLabel) =>
        date instanceof Date && isValid(date)
          ? `${formatIs(startOfWeekIs(date), 'd. MMM')} til ${formatIs(
              endOfWeekIs(date),
              'PP'
            )}`
          : invalidLabel
      }
    />
  )
}
