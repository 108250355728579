import * as React from 'react'

import * as css from './dl.css'

type Props = {
  children: React.ReactNode
}

export function Dl({ children }: Props) {
  return <dl className={css.dl}>{children}</dl>
}
