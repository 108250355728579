import { Redirect, Router } from '@reach/router'
import { User } from 'firebase/auth'
import * as React from 'react'
import { Main } from '../../components/main'
import { AdminClaims } from '../../model/user-meta/user-claims'
import * as css from './admin-shell.css'

const Overview = React.lazy(() => import('./routes/overview'))
const Care = React.lazy(() => import('./routes/care/index'))
const Management = React.lazy(() => import('./routes/management'))
const Cafeteria = React.lazy(() => import('./routes/cafeteria'))

type Props = {
  user: User
  claims: AdminClaims
}

export function AdminShell({ user, claims }: Props) {
  return (
    <Main className={css.main} withAppBar>
      <React.Suspense fallback={null}>
        <Router>
          <Redirect from="/" to="/yfirlit" noThrow />
          <Overview path="/yfirlit/*" user={user} />
          <Care path="/heimathjonusta/*" user={user} claims={claims} />
          <Cafeteria path="/motuneyti/*" />
          <Management path="/umsjon/*" user={user} />
        </Router>
      </React.Suspense>
    </Main>
  )
}
