import { CareOrder, CareOrderDocument } from '../../../model/care-order'
import { DocSnapshot } from '../utils/types'

export function parseCareOrderSnapshot(doc: DocSnapshot): CareOrder {
  const data = doc.data() as CareOrderDocument
  return parseCareOrderDocument(data, doc.id)
}

export function parseCareOrderDocument(
  data: CareOrderDocument,
  id: string
): CareOrder {
  return {
    ...data,
    id,
  }
}
