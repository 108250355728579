import { FireTimestamp } from '../../firebase/firestore/utils/types'

export function totalPortions({
  repitition: {
    portions: { mon, tue, wed, thu, fri, sat, sun },
  },
}: RepeatStatus) {
  return mon + tue + wed + thu + fri + sat + sun
}

export type CareReceiverStatus = DurationStatus | EndStatus | UnknownStatus

export type DurationStatus =
  | RepeatStatus
  | HospitalStatus
  | BedRestAdmissionStatus
  | OnPauseStatus

export type EndStatus = QuitStatus | DeceasedStatus

type IndefiniteStatusDuration = {
  type: 'indefinite'
}

type UntilStatusDuration = {
  type: 'until'
  /**
   * Inclusive. Will have the associated status until the end of this day.
   */
  until: FireTimestamp
  next: CareReceiverStatus
}

export type StatusDuration = IndefiniteStatusDuration | UntilStatusDuration

export type RedDaysValue =
  | { type: 'no' }
  | { type: 'all'; portions: number }
  | { type: 'yes' }

export type RepeatStatus = {
  type: 'repeat'
  repitition: {
    type: 'weekly'
    portions: {
      mon: number
      tue: number
      wed: number
      thu: number
      fri: number
      sat: number
      sun: number
    }
  }
  redDays: RedDaysValue
  duration: StatusDuration
}

type HospitalStatus = { type: 'hospital'; duration: StatusDuration }

type BedRestAdmissionStatus = {
  // Hvíldarinnlögn -> bed rest admission
  type: 'bed rest admission'
  duration: StatusDuration
}

type OnPauseStatus = {
  type: 'on pause'
  duration: StatusDuration
}

export type UnknownStatus = {
  type: 'unknown'
}

type QuitStatus = {
  type: 'quit'
}

type DeceasedStatus = {
  type: 'deceased'
}
