import { assertNever } from 'itils/dist/ts/assert-never'

import { CareReceiverStatus, RepeatStatus } from '.'

export function getStatusPortions({
  status,
  dayOfWeek,
  isRedDay,
}: {
  status: CareReceiverStatus
  dayOfWeek: 0 | 1 | 2 | 3 | 4 | 5 | 6
  isRedDay: boolean
}): number {
  switch (status.type) {
    case 'repeat':
      return getRepeatStatusPortions({ status, dayOfWeek, isRedDay })
    case 'bed rest admission':
    case 'deceased':
    case 'hospital':
    case 'on pause':
    case 'quit':
    case 'unknown':
      return 0
    default:
      assertNever(status)
  }
}

export function getRepeatStatusPortions({
  status,
  dayOfWeek,
  isRedDay,
}: {
  status: RepeatStatus
  dayOfWeek: 0 | 1 | 2 | 3 | 4 | 5 | 6
  isRedDay: boolean
}): number {
  if (isRedDay) {
    switch (status.redDays.type) {
      case 'all':
        return status.redDays.portions
      case 'no':
        return 0
      case 'yes':
        return getPortionsForDay({ status, dayOfWeek })
      default:
        assertNever(status.redDays)
        return 0
    }
  }
  return getPortionsForDay({ status, dayOfWeek })
}

function getPortionsForDay({
  status,
  dayOfWeek,
}: {
  status: RepeatStatus
  dayOfWeek: 0 | 1 | 2 | 3 | 4 | 5 | 6
}) {
  switch (dayOfWeek) {
    case 0:
      return status.repitition.portions.sun
    case 1:
      return status.repitition.portions.mon
    case 2:
      return status.repitition.portions.tue
    case 3:
      return status.repitition.portions.wed
    case 4:
      return status.repitition.portions.thu
    case 5:
      return status.repitition.portions.fri
    case 6:
      return status.repitition.portions.sat
    default:
      return 0
  }
}
