import {
  collection,
  onSnapshot,
  query,
  QueryConstraint,
} from 'firebase/firestore'
import * as React from 'react'
import { firestore } from '../..'
import { Fetchable } from '../../../utils/type'
import { DocSnapshot } from './types'

export function useCollection<T>({
  path,
  queryConstraints,
  parseDocument,
  deps = [],
}: {
  path: string
  queryConstraints: QueryConstraint[]
  parseDocument: (snapshot: DocSnapshot) => T
  deps: React.DependencyList
}) {
  const [data, setData] = React.useState<Fetchable<T[], Error>>({
    type: 'fetching',
  })

  React.useEffect(() => {
    console.log('useCollection subscribe', {
      path,
      queryConstraints,
      deps,
    })
    setData({ type: 'fetching' })
    const unsubscribe = onSnapshot(
      query(collection(firestore, path), ...queryConstraints),
      (snapshot) => {
        setData({
          type: 'fetched',
          data: snapshot.docs.map(parseDocument),
        })
      },
      (error) => {
        setData({ type: 'fetched failed', error })
        console.log('useCollection error', error, {
          path,
          queryConstraints,
          deps,
        })
      }
    )
    return function unSubscribeSnapshot() {
      console.log('useCollection un-subscribe', {
        path,
        queryConstraints,
        deps,
      })
      unsubscribe()
    }
  }, deps)
  return data
}
