import { H1, P } from 'ignitic/dist/core/text'
import * as React from 'react'

import { Main } from '../../../../components/main'
import * as css from './no-claims-view.css'

type Props = {}

export function NoClaimsView(props: Props) {
  return (
    <Main withAppBar className={css.container}>
      <H1 variant="h2" marginBottom>
        Engin réttindi
      </H1>
      <P>Engin réttindi hafa verið gefin þessum notanda.</P>
      <P>Hafið samband við Veislulist til að fá aðgangsréttindi.</P>
    </Main>
  )
}
