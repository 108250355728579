import HourglassFull from '@material-ui/icons/HourglassFull'
import LocalHospital from '@material-ui/icons/LocalHospital'
import LocalHotel from '@material-ui/icons/LocalHotel'
import Pause from '@material-ui/icons/Pause'
import Repeat from '@material-ui/icons/Repeat'
import Stop from '@material-ui/icons/Stop'
import Warning from '@material-ui/icons/Warning'
import { Span } from 'ignitic/dist/core/text'
import { cn } from 'itils/dist/misc/cn'
import { assertNever } from 'itils/dist/ts/assert-never'
import * as React from 'react'

import { display } from 'ignitic/dist/styles/display'
import { flex } from 'ignitic/dist/styles/flex'
import { CareReceiverStatus } from '../model/care-receiver-status'

type Props = {
  status: CareReceiverStatus
}

export function CareReceiverStatusIcon(props: Props) {
  const sharedProps = {
    className: cn(
      display.inlineFlex,
      flex.row,
      flex.alignCenter,
      flex.justifyCenter
    ),
    style: {
      flexGrow: 0,
      flexShrink: 0,
    },
  }
  switch (props.status.type) {
    case 'hospital':
      return (
        <Span {...sharedProps} color="secondary">
          <LocalHospital fontSize="small" />
        </Span>
      )
    case 'repeat':
      return (
        <Span {...sharedProps} color="primary">
          <Repeat fontSize="small" />
        </Span>
      )
    case 'on pause':
      return (
        <Span {...sharedProps} color="secondary">
          <Pause fontSize="small" />
        </Span>
      )
    case 'bed rest admission':
      return (
        <Span {...sharedProps} color="secondary">
          <LocalHotel fontSize="small" />
        </Span>
      )
    case 'quit':
      return (
        <Span {...sharedProps} color="default">
          <Stop fontSize="small" />
        </Span>
      )
    case 'deceased':
      return (
        <Span {...sharedProps} color="default">
          <HourglassFull fontSize="small" />
        </Span>
      )
    case 'unknown':
      return (
        <Span {...sharedProps} color="secondary">
          <Warning fontSize="small" />
        </Span>
      )
    default:
      assertNever(props.status)
      return null
  }
}
