import { orderBy, QueryConstraint } from 'firebase/firestore'
import { Collections } from '../collections'
import { useCollection } from '../utils/use-collection'
import { parseCustomerSnapshot } from './parse-customer-snapshot'

export function useCustomers(
  queryConstraints: QueryConstraint[] = [],
  deps: React.DependencyList = []
) {
  return useCollection({
    path: Collections.Customers,
    parseDocument: parseCustomerSnapshot,
    queryConstraints: [...queryConstraints, orderBy('name', 'asc')],
    deps,
  })
}
