import MenuItem from '@material-ui/core/MenuItem'
import * as React from 'react'

import { SelectField, SelectFieldBaseProps } from './select-field'

type Props = SelectFieldBaseProps & {
  value: string
  values?: string[]
  children?: React.ReactNode
  onChange: (value: string) => void
}

export function SelectStringField({
  values,
  onChange,
  children,
  ...rest
}: Props) {
  return (
    <SelectField {...rest} onChange={(event) => onChange(event.target.value)}>
      {values &&
        values.map((c) => (
          <MenuItem key={c} value={c}>
            {c}
          </MenuItem>
        ))}
      {values === undefined && children}
    </SelectField>
  )
}
