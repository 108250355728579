.dl {
  display: flex;
  flex-direction: column;
  margin: 0;
}
.dl dt {
  width: 7rem;
}
.dl dd {
  margin: 0;
}
.dl > div {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
}
