import { Collections } from '../collections'
import { useDocument } from '../utils/use-document'
import { dayId } from './day-id'
import { parseDaySnapsnot } from './parse-day-snapshot'

export function useDay(day: Date) {
  return useDocument({
    collection: Collections.Days,
    parseDocument: parseDaySnapsnot,
    documentId: dayId(day),
  })
}
