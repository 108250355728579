import MenuItem from '@material-ui/core/MenuItem'
import addDays from 'date-fns/addDays'
import addMonts from 'date-fns/addMonths'
import startOfDay from 'date-fns/startOfDay'
import { Timestamp } from 'firebase/firestore'
import DatePicker from 'material-ui-pickers/DatePicker'
import * as React from 'react'
import { setStatusMinDate } from '../../../firebase/firestore/care-receivers/set-status-min-date'
import { CareReceiver } from '../../../model/care-receiver'
import {
  CareReceiverStatus,
  DurationStatus,
  StatusDuration,
} from '../../../model/care-receiver-status'
import { defaultRepeatStatus } from '../../../model/care-receiver-status/default'
import { isDurationStatus } from '../../../model/care-receiver-status/is-duration-status'
import { durations } from '../../../model/care-receiver-status/values'
import { formatIs } from '../../../utils/date-fns'
import { SelectFieldBaseProps } from '../../select-field'
import { SelectStringField } from '../../select-string-field'

type Props = SelectFieldBaseProps & {
  careReceiver?: CareReceiver
  untilLabel: string
  status: DurationStatus
  setStatus: (status: CareReceiverStatus) => void
  minDate?: Date
}

export function SelectDuration({
  status,
  setStatus,
  minDate,
  untilLabel,
  careReceiver,
  ...rest
}: Props) {
  return (
    <React.Fragment>
      <SelectStringField
        {...rest}
        value={status.duration.type}
        onChange={(value) => {
          if (!isDurationStatus(status)) {
            return
          }
          switch (value as StatusDuration['type']) {
            case 'indefinite':
              setStatus({
                ...status,
                duration: {
                  type: 'indefinite',
                },
              })
              break
            case 'until':
              setStatus({
                ...status,
                duration: {
                  type: 'until',
                  until: Timestamp.fromDate(
                    minDate || startOfDay(addMonts(new Date(), 1))
                  ),
                  next:
                    careReceiver == undefined
                      ? defaultRepeatStatus
                      : careReceiver.status.type == 'repeat'
                      ? careReceiver.status
                      : careReceiver.lastRepeatStatus
                      ? careReceiver.lastRepeatStatus.status
                      : defaultRepeatStatus,
                },
              })
              break
            default:
              break
          }
        }}
      >
        {durations.map((s) => (
          <MenuItem key={s.type} value={s.type}>
            {s.label}
          </MenuItem>
        ))}
      </SelectStringField>
      {status.duration.type === 'until' && (
        <DatePicker
          minDate={minDate}
          label={untilLabel}
          value={status.duration.until.toDate()}
          onChange={(d) => {
            if (!isDurationStatus(status) || status.duration.type !== 'until') {
              return
            }
            const until = startOfDay(d)
            setStatus({
              ...status,
              duration: {
                ...status.duration,
                until: Timestamp.fromDate(until),
                next: setStatusMinDate(status.duration.next, addDays(until, 1)),
              },
            })
          }}
          animateYearScrolling
          cancelLabel="Hætta við"
          okLabel="Velja"
          autoOk
          labelFunc={(date) => {
            return formatIs(date instanceof Date ? date : date.toDate(), 'PP')
          }}
        />
      )}
    </React.Fragment>
  )
}
