import { QueryConstraint } from 'firebase/firestore'
import { Collections } from '../collections'
import { useCollection } from '../utils/use-collection'
import { parseCareReceiverSnapshot } from './parse-care-receiver-snapshot'

export function useCareReceivers(
  queryConstraints: QueryConstraint[] = [],
  deps: React.DependencyList = []
) {
  return useCollection({
    path: Collections.CareReceivers,
    queryConstraints: queryConstraints,
    parseDocument: parseCareReceiverSnapshot,
    deps,
  })
}
