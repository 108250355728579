import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import { display } from 'ignitic/dist/styles/display'
import { flex } from 'ignitic/dist/styles/flex'
import { cn } from 'itils/dist/misc/cn'
import * as React from 'react'
import { SelectField, SelectFieldBaseProps } from './select-field'

type Props = SelectFieldBaseProps & {
  value: string[]
  onChange: (value: string[]) => void
  chipIcon?: React.ReactElement<any>
  onDelete?: (value: string) => void

  values?: string[]
  children?: React.ReactNode
  getLabelFor?: (value: string) => string | null
}

export function SelectMultipleStringsField({
  values,
  onChange,
  chipIcon,
  onDelete,
  children,
  getLabelFor,
  ...rest
}: Props) {
  return (
    <SelectField
      multiple
      {...rest}
      onChange={(event) => onChange(event.target.value as any as string[])}
      renderValue={(selected) => {
        if (!Array.isArray(selected)) {
          return null
        }
        return (
          <div className={cn(display.flex, flex.row, flex.spacingSm)}>
            {selected.map((i) => {
              if (typeof i !== 'string') {
                return null
              }
              const label = getLabelFor !== undefined ? getLabelFor(i) : i
              if (label == null) {
                return null
              }
              return (
                <Chip
                  key={i}
                  label={label}
                  color="primary"
                  icon={chipIcon}
                  variant="outlined"
                  onDelete={onDelete && (() => onDelete(i))}
                />
              )
            })}
          </div>
        )
      }}
    >
      {values &&
        values.map((c) => (
          <MenuItem key={c} value={c}>
            {c}
          </MenuItem>
        ))}
      {values === undefined && children}
    </SelectField>
  )
}
