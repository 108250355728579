import { Route, RouteDocument } from '../../../model/route'
import { DocSnapshot } from '../utils/types'

export function parseRouteSnapshot(doc: DocSnapshot): Route {
  const data = doc.data() as RouteDocument
  return {
    ...data,
    id: doc.id,
  }
}
