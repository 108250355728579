import ArrowForwardOutlined from '@material-ui/icons/ArrowForwardOutlined'
import { Span } from 'ignitic/dist/core/text'
import { display } from 'ignitic/dist/styles/display'
import { flex } from 'ignitic/dist/styles/flex'
import { cn } from 'itils/dist/misc/cn'
import * as React from 'react'
import { FireTimestamp } from '../../firebase/firestore/utils/types'
import { EventBy } from '../../model/event-by'
import { formatIs } from '../../utils/date-fns'
import css from './change-log-item.css'

type Props = {
  timestamp?: FireTimestamp
  by: EventBy['by']
  children?: React.ReactNode
}

function modifiedBy(by: EventBy['by']) {
  return by.type == 'system' ? 'Kerfi' : by.email
}

export function ChangeLogItemArrow() {
  return (
    <Span style={{ lineHeight: '1rem' }} color="primary">
      <ArrowForwardOutlined />
    </Span>
  )
}

export function ChangeLogList({ children }: { children: React.ReactNode }) {
  return (
    <ol className={cn(css.changelogList, display.inlineFlex, flex.col)}>
      {children}
    </ol>
  )
}

export function ChangeLogItemBase({ timestamp, by, children }: Props) {
  return (
    <li
      className={cn(
        css.changelogItem,
        display.inlineFlex,
        flex.col,
        flex.spacingSm
      )}
    >
      <div className={cn(display.inlineFlex, flex.row, flex.alignCenter)}>
        <Span style={{ width: '10rem' }} variant="p" color="primary">
          {timestamp && formatIs(timestamp.toDate(), 'PPp')}
        </Span>
        <Span>{modifiedBy(by)}</Span>
      </div>
      <ul className={cn(display.inlineFlex, flex.col)}>{children}</ul>
    </li>
  )
}

export function ChangeLogChangeItem({
  name,
  children,
}: {
  name: string
  children: React.ReactNode
}) {
  return (
    <li
      className={cn(
        display.inlineFlex,
        flex.row,
        flex.spacingMd,
        flex.alignCenter
      )}
    >
      <Span
        style={{ minWidth: '8rem', textAlign: 'right' }}
        color="neutral"
        variant="p"
      >
        {name}:
      </Span>
      {children}
    </li>
  )
}
