import { doc, onSnapshot } from 'firebase/firestore'
import * as React from 'react'
import { firestore } from '../..'
import { Fetchable } from '../../../utils/type'
import { Collections } from '../collections'
import { DocSnapshot } from './types'

export type UseDocumentArgs<T> = {
  collection: Collections
  parseDocument: (snapshot: DocSnapshot) => T
  documentId: string
}

export function useDocument<T>({
  collection,
  documentId,
  parseDocument,
}: UseDocumentArgs<T>) {
  const [data, setData] = React.useState<Fetchable<T, Error>>({
    type: 'fetching',
  })
  React.useEffect(() => {
    setData({ type: 'fetching' })
    return onSnapshot(
      doc(firestore, collection, documentId),
      (doc) => {
        if (!doc.exists() && data.type !== 'does not exist') {
          setData({ type: 'does not exist' })
        } else if (doc.exists()) {
          setData({ type: 'fetched', data: parseDocument(doc) })
        }
      },
      (error) => {
        setData({ type: 'fetched failed', error })
      }
    )
  }, [documentId])
  return data
}
