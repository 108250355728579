import CircularProgress from '@material-ui/core/CircularProgress'
import * as React from 'react'

import { H2, P } from 'ignitic/dist/core/text'
import * as css from './setting-up-screen.css'

type Props = {}

export function SettingUpScreen(props: Props) {
  return (
    <div className={css.container}>
      <H2 variant="h3" color="primary" marginBottom>
        Augnablik
      </H2>
      <P>Verið er að setja upp aðgang.</P>
      <CircularProgress />
    </div>
  )
}
