import { CareConfig } from '../../../model/care-config'
import { Collections } from '../collections'
import { DocSnapshot } from '../utils/types'
import { useDocument } from '../utils/use-document'
import { ConfigId } from './config-id'

export function parseCareConfigSnapshot(doc: DocSnapshot): CareConfig {
  return doc.data() as CareConfig
}

export function useCareConfig() {
  return useDocument({
    collection: Collections.Configs,
    parseDocument: parseCareConfigSnapshot,
    documentId: ConfigId.Care,
  })
}
