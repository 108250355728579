import { QueryConstraint } from 'firebase/firestore'
import { Collections } from '../collections'
import { useCollection } from '../utils/use-collection'
import { parseRouteSnapshot } from './parse-route-snapshot'

export function useRoutes(
  queryConstraints: QueryConstraint[] = [],
  deps: React.DependencyList = []
) {
  return useCollection({
    path: Collections.Routes,
    parseDocument: parseRouteSnapshot,
    queryConstraints,
    deps,
  })
}
