import { addDays, getDay, isBefore, isSameDay } from 'date-fns'
import { CareOrder } from '../../model/care-order'
import { CareReceiver } from '../../model/care-receiver'
import { getCareReceiverStatusForDay } from '../../model/care-receiver-status/get-care-receiver-status-for-day'
import { getRepeatStatusPortions } from '../../model/care-receiver-status/get-repeat-status-portions'
import { days } from '../../model/care-receiver-status/values'
import { DateInterval } from '../../model/types/interval'
import { PortionsItem } from '../../model/types/portions-item'
import { getIcelandicDayType } from '../date'
import { Fetchable } from '../type'

export function calulateWeekPortions(
  week: DateInterval,
  r: CareReceiver,
  today: Date,
  orders: Fetchable<CareOrder[], Error>
): () => PortionsItem[] {
  return () =>
    days.map((d) => {
      const day = addDays(week.from, d.day)
      const statusForDay = getCareReceiverStatusForDay(r.status, day)
      const icelandicDayType = getIcelandicDayType(day)
      const dayOfWeek = getDay(day) as 0 | 1 | 2 | 3 | 4 | 5 | 6
      const past = isBefore(day, today)
      const order =
        orders.type == 'fetched'
          ? orders.data.find(
              (i) => i.receiver.id == r.id && isSameDay(i.day.toDate(), day)
            )
          : undefined
      const type: PortionsItem['day']['type'] = past
        ? 'past'
        : isSameDay(day, today)
        ? 'today'
        : 'future'
      const source: PortionsItem['source'] =
        orders.type == 'fetched'
          ? order != undefined
            ? { type: 'order', order }
            : { type: 'repeat' }
          : { type: 'not fetched' }
      return {
        day: {
          ...d,
          type,
          date: day,
          icelandicDayType,
        },
        portions:
          order != undefined
            ? order.portions
            : statusForDay.type == 'repeat' && type == 'future'
            ? getRepeatStatusPortions({
                status: statusForDay,
                dayOfWeek,
                isRedDay:
                  icelandicDayType.type === 'red' ||
                  icelandicDayType.type === 'big red',
              })
            : 0,
        source,
      }
    })
}
