import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { User } from 'firebase/auth'
import { Button } from 'ignitic/dist/core/button'
import { IconButton } from 'ignitic/dist/core/icon-button'
import { P, Span } from 'ignitic/dist/core/text'
import { display } from 'ignitic/dist/styles/display'
import { flex } from 'ignitic/dist/styles/flex'
import { cn } from 'itils/dist/misc/cn'
import * as React from 'react'
import { version } from '../../../../package.json'
import { AppBar, SiteMap } from '../../../components/app-bar'
import { auth } from '../../../firebase'
import { UserCircleIcon } from '../../../icons/user-circle-icon'
import { UserClaims } from '../../../model/user-meta/user-claims'
import { print } from '../../../styles/print'
import { AdminShell } from '../../admin'
import { CustomerShell } from '../../customer'
import * as css from './logged-in-shell.css'
import { NoClaimsView } from './no-claims-view'
import { SettingUpScreen } from './setting-up-screen/setting-up-screen'

type Props = {
  user: User
  claims: UserClaims
}

const adminSiteMap: SiteMap = [
  {
    to: '/yfirlit',
    active: 'partial',
    label: 'Yfirlit',
    subTree: [],
    subTreeMatch: '/yfirlit/*',
  },
  {
    to: '/heimathjonusta',
    active: 'partial',
    label: 'Heimaþjónusta',
    subTreeMatch: '/heimathjonusta/*',
    subTree: [
      { to: '/heimathjonusta/skyrsla-dagsins', label: 'Skýrsla dagsins' },
      { to: '/heimathjonusta/listi', label: 'Listi' },
      { to: '/heimathjonusta/samtolur', label: 'Samtölur' },
    ],
  },
  {
    to: '/motuneyti',
    active: 'partial',
    label: 'Mötuneyti',
    subTree: [],
    subTreeMatch: '/motuneyti/*',
  },
  {
    to: '/umsjon',
    active: 'partial',
    label: 'Umsjón',
    subTree: [
      { to: '/umsjon/stillingar', label: 'Stillingar' },
      { to: '/umsjon/akstur', label: 'Akstur' },
      { to: '/umsjon/notendur', label: 'Notendur' },
      { to: '/umsjon/logs', label: 'Loggar' },
    ],
    subTreeMatch: '/umsjon/*',
  },
]
const customerManagerSiteMap: SiteMap = [
  {
    to: '/heimathjonusta/listi',
    active: 'current',
    label: 'Listi',
    subTree: [],
    subTreeMatch: '/heimathjonusta/listi',
  },
  {
    to: '/heimathjonusta/yfirlit-dags',
    active: 'current',
    label: 'Yfirlit dags',
    subTree: [],
    subTreeMatch: '/heimathjonusta/yfirlit-dags',
  },
  {
    to: '/heimathjonusta/samtolur',
    active: 'current',
    label: 'Samtölur',
    subTree: [],
    subTreeMatch: '/heimathjonusta/samtolur',
  },
]

export function LoggedInShell({ user, claims }: Props) {
  const [authDialogOpen, setAuthDialogOpen] = React.useState<boolean>(false)
  console.log('LoggedInShell render', { claims })
  return (
    <div className={cn(css.shell, print.g)}>
      <TestSystemText />
      <AppBar
        siteMap={
          claims.role == 'admin'
            ? adminSiteMap
            : claims.role == 'customer manager'
            ? customerManagerSiteMap
            : []
        }
      >
        <IconButton
          className={css.accountMenuButton}
          aria-owns={authDialogOpen ? 'auth-dialog' : undefined}
          aria-haspopup="true"
          onClick={() => {
            setAuthDialogOpen(true)
          }}
          color={process.env.NODE_ENV != 'production' ? 'default' : 'neutral'}
          aria-label="Opna notanda sprettiglugga"
        >
          <UserCircleIcon />
        </IconButton>
        <Dialog
          id="auth-dialog"
          open={authDialogOpen}
          onClose={() => setAuthDialogOpen(false)}
          aria-labelledby="auth-dialog-title"
        >
          <DialogTitle id="auth-dialog-title">
            Innskráður sem {user.email}
          </DialogTitle>
          <DialogContent>
            <P variant="p.small">Útgáfa {version}</P>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              color="primary"
              onClick={() => auth.signOut()}
            >
              Útskrá
            </Button>
          </DialogActions>
        </Dialog>
      </AppBar>
      {claims.role == undefined && <SettingUpScreen />}
      {claims.role == 'no claims' && <NoClaimsView />}
      {claims.role == 'admin' && <AdminShell user={user} claims={claims} />}
      {claims.role == 'customer manager' && (
        <CustomerShell user={user} claims={claims} />
      )}
    </div>
  )
}

function TestSystemText() {
  if (process.env.NODE_ENV != 'production') {
    return (
      <div
        className={cn(
          display.none,
          display.sm_flex,
          flex.row,
          flex.alignCenter
        )}
        style={{
          position: 'fixed',
          top: '.8rem',
          right: '3rem',
          zIndex: 99999,
        }}
      >
        <Span color="default">Prufukerfi</Span>
      </div>
    )
  }
  return null
}
