import { Span } from 'ignitic/dist/core/text'
import * as React from 'react'

type Props = { term: string; data: string }

export function DlItem({ term, data }: Props) {
  return (
    <div>
      <dt>
        <Span color="primary">{term}</Span>
      </dt>
      <dd>
        <Span>{data}</Span>
      </dd>
    </div>
  )
}
