.e3c724 {
  font-size: var(--font-size-p);
  line-height: var(--line-height-p);
  font-weight: 300;
  color: var(--neutral-on-background-color);
  display: inline-flex;
  flex-direction: row;
  width: 100%;
}
.e3c724 > * + * {
  margin-left: var(--spacer);
}
.e3c724.ba4a23 {
  flex-direction: column;
}
.e3c724.ba4a23 > * + * {
  margin: 0;
}
