import Popover from '@material-ui/core/Popover'
import { IconButton } from 'ignitic/dist/core/icon-button'
import { useUUID } from 'ignitic/dist/hooks/use-uuid'
import { Tooltip } from 'ignitic/dist/popups/tooltip'
import * as React from 'react'

type Props = {
  children: React.ReactNode
  icon: React.ReactNode
  label: string
  className?: string
}

export function PopoverIconButton(props: Props) {
  const [open, setOpen] = React.useState(false)
  const ref = React.useRef<HTMLButtonElement>(null)
  const tooltipId = useUUID()
  const id = useUUID()
  return (
    <React.Fragment>
      <IconButton
        aria-describedby={tooltipId}
        ref={ref}
        aria-label={props.label}
        aria-haspopup="true"
        aria-owns={open ? id : undefined}
        onClick={() => setOpen(!open)}
        className={props.className}
      >
        {props.icon}
      </IconButton>
      <Tooltip triggerRef={ref} id={tooltipId}>
        {props.label}
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {props.children}
      </Popover>
    </React.Fragment>
  )
}
