import { RepeatStatus } from '.'

/**
 * Immutably set's the portion for the given RepeatStatus
 * and day of the week.
 */
export function setPortion(
  status: RepeatStatus,
  day: keyof RepeatStatus['repitition']['portions'],
  portions: number
): RepeatStatus {
  return {
    ...status,
    repitition: {
      ...status.repitition,
      portions: {
        ...status.repitition.portions,
        [day]: portions,
      },
    },
  }
}
