import { useCallback, useState } from 'react'

export function useLocalStorage<T>({
  key,
  defaultValue,
}: {
  key: string
  defaultValue: T
}): [T, (value: T) => void] {
  const [state, setState] = useState(() => {
    const item = window.localStorage.getItem(key)
    if (item === null) {
      return defaultValue
    }
    return JSON.parse(item) as T
  })
  const setLocalState = useCallback(
    (value: T) => {
      setState(value)
      window.localStorage.setItem(key, JSON.stringify(value))
    },
    [key]
  )
  return [state, setLocalState]
}
