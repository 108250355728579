import { FirebaseError } from 'firebase/app'
import { H1, H2, P } from 'ignitic/dist/core/text'
import { Paper } from 'ignitic/dist/surfaces/paper'
import * as React from 'react'

import { UserState } from '../../../firebase/auth/use-auth-state'
import { getAuthErrorMessage } from './get-auth-error-message'
import * as css from './login.shared.css'
import { SkutanLogo } from './skutan-logo'

type Props = {
  error: FirebaseError
  setUserState: (state: UserState) => void
}

export const LoginErrorPage = (props: Props) => {
  if (props.error.code === 'auth/invalid-email') {
    props.setUserState({ type: 'signing in with email link but email missing' })
    return null
  }
  return (
    <main className={css.main}>
      <SkutanLogo />
      <H1 variant="h3">Pöntunarkerfi fyrir heimaþjónustu bæjarfélaga</H1>
      <Paper className={css.paper}>
        <H2 color="primary" variant="h4" marginBottom>
          Innskráningar villa
        </H2>
        <P color="secondary">{getAuthErrorMessage(props.error)}</P>
        <a className={css.tryAgainLink} href={window.location.origin}>
          Reyna aftur
        </a>
      </Paper>
    </main>
  )
}
