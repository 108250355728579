import * as React from 'react'

import * as css from './skutan-logo.css'

type Props = {
  className?: string
}

export function SkutanLogo({ className }: Props) {
  return (
    <svg className={className} viewBox="0 0 300 300">
      <path
        className={css.ocean}
        d="M42.28,260.58C53.11,247.39,74.71,244.2,91,253.46c8.74,5,16.87,10.89,25.89,15.5,14.88,7.61,32,3.84,39.67-10.35,4.62-8.49,10.67-10,21.27-7.32a96.08,96.08,0,0,1,19,7.41,360.74,360.74,0,0,1,51.86,31.05c3.17,2.27,6.76,4.22,8.92,8.23-3.23.91-5.56-.91-8-1.85-18.36-7.1-35.87-16.33-54.82-21.94-3.14-.93-6.36-1.59-9.51-2.51-9.43-2.75-17.27.05-23.34,7.33-18.86,22.6-25.55,18-46,8.77a181.55,181.55,0,0,1-27.13-14.72C74.63,263.47,59.9,256.6,42.19,260.5Z"
      />
      <path
        className={css.mast}
        d="M129,264.72c-5.28-3-8.06-7-12.62-8.75-3.24-1.22-2.34-4.8-2.36-7.5-.12-19.74,2.16-39.33,4.32-58.91,3.5-31.68,6.61-63.45,12.6-94.78,5.15-26.94,13.05-53.23,20.09-79.71a6.18,6.18,0,0,1,2.26-3.29C142.63,95.52,133.59,179.28,129,264.72Z"
      />
      <path
        className={css.sails}
        d="M171,10.64c15.86,83.88,46.92,163.13,74.77,244.15-4.32-1.24-4.7-4.41-6-6.66-18-32-31-66.15-42-101.15-9.64-30.66-19.7-61.17-25-92.93C170.38,39.76,168.59,25.32,171,10.64Z"
      />
      <path
        className={css.sails}
        d="M48.18,198.61c-1.16-37.8,33.48-100.43,62-110.43-12.66,19.14-26,35.52-36.52,53.69C63.25,159.87,56.73,179.72,48.18,198.61Z"
      />
    </svg>
  )
}
