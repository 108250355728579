import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { firestore } from '../..'
import { NewCareReceiverDocument } from '../../../model/care-receiver'
import { CareReceiverStatus } from '../../../model/care-receiver-status'
import { Collections } from '../collections'

type Arg = {
  name: string
  deliveryLocation: string
  kennitala: string
  customerId: string
  customerName: string
  customerNote: string
  careProviderNote: string
  foodType: string
  foodRestrictions: string[]
  status: CareReceiverStatus
  phone1?: string
  phone2?: string
  relativePhone?: string
  route: {
    id: string
    name: string
  } | null
  createdBy:
    | {
        type: 'user'
        uid: string
        email: string
      }
    | {
        type: 'system'
      }
}

export function addCareReceiver({
  createdBy,
  route,
  phone1,
  phone2,
  relativePhone,
  ...rest
}: Arg) {
  const doc: NewCareReceiverDocument = {
    ...rest,
    delivery: { type: 'not set' },
    created: {
      timestamp: serverTimestamp() as any,
      by: createdBy,
    },
  }
  if (phone1) {
    doc.phone1 = phone1
  }
  if (phone2) {
    doc.phone2 = phone2
  }
  if (relativePhone) {
    doc.relativePhone = relativePhone
  }
  return addDoc(collection(firestore, Collections.CareReceivers), doc)
}
