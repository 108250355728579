import MenuItem from '@material-ui/core/MenuItem'
import * as React from 'react'

import { Route } from '../../model/route'
import { SelectFieldBaseProps } from '../select-field'
import { SelectStringField } from '../select-string-field'

type Props = SelectFieldBaseProps & {
  value: string
  routes: Route[]
  setRoute: (value: Route | null) => void
}

export function SelectRouteField({ value, routes, setRoute, ...rest }: Props) {
  return (
    <SelectStringField
      {...rest}
      value={value}
      onChange={(value) => {
        const route = routes.find((i) => i.id === value)
        if (route === undefined) {
          setRoute(null)
        } else {
          setRoute(route)
        }
      }}
    >
      <MenuItem value="">Ekki valið</MenuItem>
      {routes.map((c) => (
        <MenuItem key={c.id} value={c.id}>
          {c.name}
        </MenuItem>
      ))}
    </SelectStringField>
  )
}
