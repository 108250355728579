import { Link, RouteComponentProps } from '@reach/router'
import { linkStyles } from 'ignitic/dist/core/link'
import { display } from 'ignitic/dist/styles/display'
import { flex } from 'ignitic/dist/styles/flex'
import { padding } from 'ignitic/dist/styles/spacing'
import { Surface } from 'ignitic/dist/surfaces/surface'
import { cn } from 'itils/dist/misc/cn'
import * as React from 'react'
import { currentLink } from '.'
import css from './app-bar.css'

type Props = {
  links: { to: string; label: string }[]
} & RouteComponentProps

export function SubBar(props: Props) {
  return (
    <Surface
      className={cn(
        display.flex,
        flex.row,
        flex.alignCenter,
        padding.smX,
        css.subBar
      )}
      color="paper"
    >
      {props.links.map((l) => (
        <Link
          key={l.to}
          to={l.to}
          className={cn(linkStyles.neutral, css.navLink)}
          getProps={currentLink(css.navLink)}
        >
          {l.label}
        </Link>
      ))}
    </Surface>
  )
}
