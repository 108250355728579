import DateFnsUtils from '@date-io/date-fns'
import {
  createHistory,
  HistoryListenerParameter,
  LocationProvider,
} from '@reach/router'
import { Timestamp } from 'firebase/firestore'
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider'
import * as React from 'react'
import { useAuthState, UserState } from '../../firebase/auth/use-auth-state'
import { addRouteLog } from '../../firebase/firestore/logs/add-log'
import { is } from '../../utils/date-fns'
import './app.css'
import { LoggedInShell } from './logged-in-shell'
import { EmailForSignInPage } from './login/email-for-sign-in-page'
import { LoginErrorPage } from './login/login-error-page'
import { LoginPage } from './login/login-page'
import './print.css'

// listen to the browser history
let history = createHistory(window as any)
const listener = (
  param: HistoryListenerParameter,
  userState: UserState,
  instanceId: string
) => {
  console.log(`HistoryListener ${param.action} ${param.location.pathname}`, {
    ...userState,
    ...param,
  })
  addRouteLog({
    instanceId,
    timestamp: Timestamp.now(),
    type: 'route log',
    level: 'info',
    user:
      userState.type == 'logged in'
        ? {
            type: 'user',
            email: userState.user.email || '',
            uid: userState.user.uid,
          }
        : { type: 'unauthenticated' },
    userAgent: window.navigator.userAgent,
    action: param.action,
    pathname: param.location.pathname,
    search: param.location.search,
    onLine: window.navigator.onLine,
  })
  return () => {}
}

export function App({ instanceId }: { instanceId: string }) {
  const authState = useAuthState()
  React.useEffect(() => {
    return history.listen((p) => listener(p, authState.userState, instanceId))
  }, [authState.userState, instanceId])
  React.useEffect(() => {
    if (
      authState.userState.type == 'logged in' ||
      authState.userState.type == 'not logged in'
    ) {
      listener(
        { location: history.location, action: 'PUSH' },
        authState.userState,
        instanceId
      )
    }
  }, [authState.userState])
  return (
    <LocationProvider history={history}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={is}>
        <React.Fragment>
          {authState.userState.type === 'not logged in' && (
            <LoginPage instanceId={instanceId} />
          )}
          {authState.userState.type ===
            'signing in with email link but email missing' && (
            <EmailForSignInPage />
          )}
          {authState.userState.type === 'error' && (
            <LoginErrorPage
              error={authState.userState.error}
              setUserState={authState.setUserState}
            />
          )}
          {authState.userState.type === 'logged in' &&
            authState.userMeta.type === 'fetched' &&
            (authState.claims.type === 'fetched' ||
              authState.claims.type === 'refreshing') && (
              <LoggedInShell
                user={authState.userState.user}
                claims={authState.claims.data}
              />
            )}
        </React.Fragment>
      </MuiPickersUtilsProvider>
    </LocationProvider>
  )
}
