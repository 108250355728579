import { User } from 'firebase/auth'
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { firestore } from '../..'
import { UpdateCareReceiverDocument } from '../../../model/care-receiver'
import { Omit } from '../../../utils/type'
import { Collections } from '../collections'

type Arg = {
  id: string
  update: Omit<UpdateCareReceiverDocument, 'lastModified'>
  user: User | 'system'
}

export function updateCareReceiver({ id, update, user }: Arg) {
  const updateWithLastMod: UpdateCareReceiverDocument = {
    ...update,
    lastModified: {
      timestamp: serverTimestamp() as any,
      by:
        user == 'system'
          ? { type: 'system' }
          : { type: 'user', uid: user.uid, email: user.email as string },
    },
  }
  console.log('updateCareReceiver', updateWithLastMod)
  return updateDoc(
    doc(firestore, Collections.CareReceivers, id),
    updateWithLastMod
  )
}
