import { IconButton } from 'ignitic/dist/core/icon-button'
import * as React from 'react'
import { PrinterIcon } from '../icons/printer-icon'

type Props = {
  className?: string
}

export function PrintIconButton(props: Props) {
  return (
    <IconButton
      aria-label="Prenta þessa síðu"
      onClick={() => {
        window.print()
      }}
      {...props}
    >
      <PrinterIcon />
    </IconButton>
  )
}
