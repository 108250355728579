import ArrowDownwardOutlined from '@material-ui/icons/ArrowDownwardOutlined'
import { addDays } from 'date-fns/esm'
import { Span } from 'ignitic/dist/core/text'
import { Surface } from 'ignitic/dist/surfaces/surface'
import { cn } from 'itils/dist/misc/cn'
import { assertNever } from 'itils/dist/ts/assert-never'
import * as React from 'react'

import { display } from 'ignitic/dist/styles/display'
import { flex } from 'ignitic/dist/styles/flex'
import {
  CareReceiverStatus,
  RedDaysValue,
} from '../../../model/care-receiver-status'
import { careReceiverStatusToText } from '../../../model/care-receiver-status/care-receiver-status-to-text'
import { isDurationStatus } from '../../../model/care-receiver-status/is-duration-status'
import { formatIs } from '../../../utils/date-fns'
import { CareReceiverStatusIcon } from '../../care-receiver-status-icon'
import { Dl, DlItem } from '../../dl'
import * as css from './care-receiver-status-view.css'

type Props = {
  status: CareReceiverStatus
  from?: Date
}

export function CareReceiverStatusView({ status, from }: Props) {
  return (
    <React.Fragment>
      <Surface
        color="paper"
        border="default"
        rounded
        padding="md"
        className={cn(display.flex, flex.col, flex.spacingMd)}
        style={{ maxWidth: '23rem' }}
      >
        <div
          className={cn(
            display.inlineFlex,
            flex.row,
            flex.spacingSm,
            flex.alignCenter
          )}
        >
          <CareReceiverStatusIcon status={status} />
          <Span>{careReceiverStatusToText(status)}</Span>
        </div>
        {from && (
          <Dl>
            <DlItem term="Frá og með" data={formatIs(from, 'PP')} />
          </Dl>
        )}
        {status.type == 'repeat' && (
          <div className={css.portionsContainer}>
            <Span color="primary">Mán</Span>
            <Span color="primary">Þri</Span>
            <Span color="primary">Mið</Span>
            <Span color="primary">Fim</Span>
            <Span color="primary">Fös</Span>
            <Span color="primary">Lau</Span>
            <Span color="primary">Sun</Span>
            <Span>{status.repitition.portions.mon}</Span>
            <Span>{status.repitition.portions.tue}</Span>
            <Span>{status.repitition.portions.wed}</Span>
            <Span>{status.repitition.portions.thu}</Span>
            <Span>{status.repitition.portions.fri}</Span>
            <Span>{status.repitition.portions.sat}</Span>
            <Span>{status.repitition.portions.sun}</Span>
          </div>
        )}
        {isDurationStatus(status) && (
          <Dl>
            {status.type == 'repeat' && (
              <DlItem
                term="Rauðir dagar"
                data={redDaysValueToText(status.redDays)}
              />
            )}
            <DlItem
              term="Til og með"
              data={
                status.duration.type == 'indefinite'
                  ? 'ótiltekið'
                  : formatIs(status.duration.until.toDate(), 'PP')
              }
            />
          </Dl>
        )}
      </Surface>
      {isDurationStatus(status) && status.duration.type == 'until' && (
        <React.Fragment>
          <div
            style={{ maxWidth: '23rem' }}
            className={cn(
              display.flex,
              flex.col,
              flex.justifyCenter,
              flex.alignCenter
            )}
          >
            <Span
              className={cn(display.inlineFlex, flex.col, flex.justifyCenter)}
              color="primary"
            >
              <ArrowDownwardOutlined />
            </Span>
          </div>
          <CareReceiverStatusView
            status={status.duration.next}
            from={addDays(status.duration.until.toDate(), 1)}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

function redDaysValueToText(v: RedDaysValue): string {
  switch (v.type) {
    case 'all':
      return `alltaf ${v.portions}`
    case 'yes':
      return 'já'
    case 'no':
      return 'nei'
    default:
      assertNever(v)
      return ''
  }
}
