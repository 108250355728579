import TextField from '@material-ui/core/TextField'
import { display } from 'ignitic/dist/styles/display'
import { flex } from 'ignitic/dist/styles/flex'
import { cn } from 'itils/dist/misc/cn'
import * as React from 'react'
import { CareReceiverStatus } from '../../../model/care-receiver-status'
import { setPortion } from '../../../model/care-receiver-status/set-portion'
import { days } from '../../../model/care-receiver-status/values'

type Props = {
  status: CareReceiverStatus
  setStatus: (status: CareReceiverStatus) => void
}

export function PortionsTextFields(props: Props) {
  return (
    <div className={cn(display.flex, flex.row, flex.spacingMd)}>
      {days.map((d) => {
        if (props.status.type !== 'repeat') {
          return null
        }
        return (
          <TextField
            key={d.value}
            label={d.label}
            value={props.status.repitition.portions[d.value]}
            onChange={(event) => {
              const portions = parseInt(event.target.value, 10)
              if (
                isNaN(portions) ||
                props.status.type !== 'repeat' ||
                portions < 0
              ) {
                return
              }
              props.setStatus(setPortion(props.status, d.value, portions))
            }}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            helperText=" "
          />
        )
      })}
    </div>
  )
}
