.a3c1aa {
  margin-bottom: calc(-1 * var(--border-radius));
}
.c879da {
  background: transparent;
  color: var(--neutral-on-background-color);
  border: none;
  margin: 0;
  padding: var(--space-sm) var(--space-md) var(--space-sm) var(--space-md);
  outline-offset: calc(-1 * var(--space-sm));
  --btn-font-size: var(--font-size-p);
  --btn-line-height: var(--btn-line-height-md);
  font-size: var(--btn-font-size);
  line-height: calc(var(--btn-line-height) + var(--border-radius));
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.057em;
}
.c879da.adc343 {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}
.c879da.b0e228[aria-selected='true'] {
  background: var(--neutral-surface-color);
  color: var(--neutral-on-neutral-surface-color);
}
.c879da.b96433[aria-selected='true'] {
  background: var(--paper-surface-color);
  color: var(--neutral-on-paper-surface-color);
}
