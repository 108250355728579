import TextField from '@material-ui/core/TextField'
import { FirebaseError } from 'firebase/app'
import { ActionCodeSettings, sendSignInLinkToEmail } from 'firebase/auth'
import { Timestamp } from 'firebase/firestore'
import { Button } from 'ignitic/dist/core/button'
import { H1, H2, P } from 'ignitic/dist/core/text'
import { Paper } from 'ignitic/dist/surfaces/paper'
import * as React from 'react'
import { auth } from '../../../firebase'
import { addErrorBoundaryLog } from '../../../firebase/firestore/logs/add-log'
import { useTextInput } from '../../../hooks/useTextInput'
import { isEmail } from '../../../utils/isEmail'
import * as css from './login.shared.css'
import { SkutanLogo } from './skutan-logo'

type Props = {
  instanceId: string
}

export const LoginPage = (props: Props) => {
  const [email, , emailInputProps] = useTextInput('')
  const [state, setState] = React.useState<
    | { type: 'input' }
    | { type: 'sending' }
    | { type: 'sent' }
    | { type: 'error'; error: FirebaseError }
  >({ type: 'input' })

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setState({ type: 'sending' })
    const actionCodeSettings: ActionCodeSettings = {
      url: window.location.href,
      handleCodeInApp: true,
    }
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(function () {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email)
        setState({ type: 'sent' })
      })
      .catch((error) => {
        setState({ type: 'error', error })
        addErrorBoundaryLog({
          instanceId: props.instanceId,
          timestamp: Timestamp.now(),
          type: 'error boundary log',
          level: 'error',
          userAgent: window.navigator.userAgent,
          user: { type: 'unauthenticated' },
          error: {
            message: error.message,
            name: error.code,
          },
          errorInfo: {
            componentStack: '',
          },
          env: process.env.NODE_ENV,
        })
      })
  }
  return (
    <main className={css.main}>
      <SkutanLogo />
      <H1 variant="h3">Pöntunarkerfi fyrir heimaþjónustu bæjarfélaga</H1>
      <P>
        Hér er hægt að skrá sig inn með tölvupósti. Hafið samband við Veislulist
        til að fá aðgangsréttindi.
      </P>
      <Paper className={css.paper}>
        <H2 color="primary" variant="h4" marginBottom>
          Innskráning með tölvupósti
        </H2>
        {(state.type === 'input' || state.type === 'sending') && (
          <form
            className={css.form}
            noValidate
            autoComplete="off"
            onSubmit={onSubmit}
          >
            <TextField
              disabled={state.type !== 'input'}
              fullWidth
              label="Email"
              {...emailInputProps}
              variant="standard"
              helperText="Tölvupóstur verður sendur á þetta póstfang með tengli sem skráir þig inn."
            />
            <Button
              disabled={state.type !== 'input' || !isEmail(email)}
              type="submit"
              variant="filled"
            >
              Áfram
            </Button>
          </form>
        )}
        {state.type === 'sent' && (
          <P>
            Tölvupóstur hefur verið sendur á póstfangið {email}. Vinsamlegast
            opnið viðkomandi póst og smellið á tengilinn þar inni til að klára
            innskráningu.
          </P>
        )}
        {state.type == 'error' && (
          <>
            {state.error.code == 'auth/invalid-email' && (
              <>
                <P>Email er á röngu formi</P>
              </>
            )}
            {state.error.code != 'auth/invalid-email' && (
              <>
                <P color="default">Upp hefur komið villa</P>
                <P>{state.error.code}</P>
                <P>{state.error.message}</P>
              </>
            )}
            <Button
              variant="filled"
              onClick={() => setState({ type: 'input' })}
            >
              Reyna aftur
            </Button>
          </>
        )}
      </Paper>
    </main>
  )
}
