export const enum Collections {
  CareOrders = 'careOrders',
  CareReceivers = 'careReceivers',
  Configs = 'configs',
  Customers = 'customers',
  CustomRouteOrderings = 'customRouteOrderings',
  Days = 'days',
  InAdvanceClaims = 'inAdvanceClaims',
  Logs = 'logs',
  MailMetas = 'mailMetas',
  Mails = 'mails',
  Routes = 'routes',
  UserMetas = 'userMetas',
}
