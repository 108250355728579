import { getNotInOrderRouteId } from '../../client/admin/routes/overview/routes-papers-page/get-not-in-order-route-id'
import { CareOrder } from '../../model/care-order'
import { deliveryId, deliveryName } from '../../model/care-order/delivery-id'
import { CareReceiverId } from '../../model/care-receiver'
import { RouteOrderings } from '../../model/custom-route-ordering'
import { OrderProperty, Route } from '../../model/route'

export function calculateRouteOrderings(
  orders: CareOrder[],
  routes: Route[],
  orderProperty: OrderProperty
) {
  const nonZeroOrders = orders.filter((i) => i.portions > 0)
  const result: RouteOrderings = {}
  const rIdsInOrdering = new Set<CareReceiverId>()
  nonZeroOrders.forEach((i) => {
    const routeId = deliveryId(i.delivery)
    const routeName = deliveryName(i.delivery)
    const notInOrderRouteId = getNotInOrderRouteId(routeId)
    if (!result[routeId]) {
      const ordersInThisRoute = nonZeroOrders.filter(
        (o) =>
          deliveryId(o.delivery) == routeId &&
          !rIdsInOrdering.has(o.receiver.id)
      )
      const route = routes.find((r) => r.id == routeId)
      const routeOrder = route ? route[orderProperty] : []
      type OrderIndex = number
      const receiverOrderIndex = new Map<CareReceiverId, OrderIndex>()
      routeOrder.forEach((receiverId, index) => {
        receiverOrderIndex.set(receiverId, index)
      })
      const order = ordersInThisRoute
        .filter((o) => routeId == '-' || receiverOrderIndex.has(o.receiver.id))
        .sort((a, b) => {
          const aIndex = receiverOrderIndex.get(a.receiver.id)
          const bIndex = receiverOrderIndex.get(b.receiver.id)
          return (
            (aIndex == undefined ? Number.MAX_VALUE : aIndex) -
            (bIndex == undefined ? Number.MAX_VALUE : bIndex)
          )
        })
        .map((o) => o.receiver.id)
      if (order.length > 0) {
        result[routeId] = {
          type: routeId == '-' ? '-' : 'route order',
          routeId,
          routeName,
          order,
        }
        order.forEach((o) => {
          rIdsInOrdering.add(o)
        })
      }
    }
    if (result[notInOrderRouteId] == undefined) {
      const ordersInThisRoute = nonZeroOrders.filter(
        (o) =>
          deliveryId(o.delivery) == routeId &&
          !rIdsInOrdering.has(o.receiver.id)
      )
      const route = routes.find((r) => r.id == routeId)
      const routeDefaultOrder = route ? route[orderProperty] : []
      type OrderIndex = number
      const receiverOrderIndex = new Map<CareReceiverId, OrderIndex>()
      routeDefaultOrder.forEach((receiverId, index) => {
        receiverOrderIndex.set(receiverId, index)
      })
      const notInOrder = ordersInThisRoute
        .filter((o) => !receiverOrderIndex.has(o.receiver.id))
        .map((o) => o.receiver.id)
      if (notInOrder.length > 0) {
        result[notInOrderRouteId] = {
          type: 'route not in order',
          routeId,
          routeName: `${routeName} - Ekki í röð`,
          order: notInOrder,
        }
      }
    }
  })
  return result
}
