import { Collections } from '../collections'
import { useDocument } from '../utils/use-document'
import { customRouteOrderingId } from './custom-route-ordering-id'
import { parseCustomRouteOrderingSnapshot } from './parse-custom-route-ordering-snapshot'

export function useCustomRouteOrderingForDay(day: Date) {
  return useDocument({
    collection: Collections.CustomRouteOrderings,
    parseDocument: parseCustomRouteOrderingSnapshot,
    documentId: customRouteOrderingId(day),
  })
}
