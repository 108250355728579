import {
  CareReceiverStatus,
  DurationStatus,
  RedDaysValue,
  RepeatStatus,
  StatusDuration,
  UnknownStatus,
} from '.'
import { careReceiverStatusTypeToText } from './care-receiver-status-to-text'

export type CareReceiverStatuses<TExtra = never> = {
  type: CareReceiverStatus['type'] | TExtra
  label: string
}[]

export const allUpdateToStatuses: CareReceiverStatuses<
  'repeat empty' | 'repeat last' | 'repeat mod'
> = [
  {
    type: 'repeat',
    label: `${careReceiverStatusTypeToText('repeat')} - Núverandi virka staða `,
  },
  {
    type: 'repeat last',
    label: `${careReceiverStatusTypeToText('repeat')} - Seinasta virka staða`,
  },
  {
    type: 'repeat empty',
    label: `${careReceiverStatusTypeToText('repeat')} - Tóm virk staða`,
  },
  {
    type: 'repeat mod',
    label: `${careReceiverStatusTypeToText('repeat')}`,
  },
  { type: 'hospital', label: careReceiverStatusTypeToText('hospital') },
  {
    type: 'bed rest admission',
    label: careReceiverStatusTypeToText('bed rest admission'),
  },
  { type: 'on pause', label: careReceiverStatusTypeToText('on pause') },
  { type: 'unknown', label: careReceiverStatusTypeToText('unknown') },
  { type: 'quit', label: careReceiverStatusTypeToText('quit') },
  { type: 'deceased', label: careReceiverStatusTypeToText('deceased') },
]
export const allStatuses: CareReceiverStatuses = [
  {
    type: 'repeat',
    label: `${careReceiverStatusTypeToText('repeat')} - Núverandi staða `,
  },
  { type: 'hospital', label: careReceiverStatusTypeToText('hospital') },
  {
    type: 'bed rest admission',
    label: careReceiverStatusTypeToText('bed rest admission'),
  },
  { type: 'on pause', label: careReceiverStatusTypeToText('on pause') },
  { type: 'unknown', label: careReceiverStatusTypeToText('unknown') },
  { type: 'quit', label: careReceiverStatusTypeToText('quit') },
  { type: 'deceased', label: careReceiverStatusTypeToText('deceased') },
]

export const newCareReceiverStatuses: CareReceiverStatuses<'repeat mod'> = [
  {
    type: 'repeat mod',
    label: `${careReceiverStatusTypeToText('repeat')}`,
  },
  { type: 'hospital', label: careReceiverStatusTypeToText('hospital') },
  {
    type: 'bed rest admission',
    label: careReceiverStatusTypeToText('bed rest admission'),
  },
  { type: 'on pause', label: careReceiverStatusTypeToText('on pause') },
  { type: 'unknown', label: careReceiverStatusTypeToText('unknown') },
]

export const durationOrUnknownStatuses: {
  type: DurationStatus['type'] | UnknownStatus['type']
  label: string
}[] = [
  { type: 'repeat', label: careReceiverStatusTypeToText('repeat') },
  { type: 'hospital', label: careReceiverStatusTypeToText('hospital') },
  {
    type: 'bed rest admission',
    label: careReceiverStatusTypeToText('bed rest admission'),
  },
  { type: 'on pause', label: careReceiverStatusTypeToText('on pause') },
  { type: 'unknown', label: careReceiverStatusTypeToText('unknown') },
]

export const days: {
  label: string
  value: keyof RepeatStatus['repitition']['portions']
  day: 0 | 1 | 2 | 3 | 4 | 5 | 6
}[] = [
  { label: 'Mán', value: 'mon', day: 0 },
  { label: 'Þri', value: 'tue', day: 1 },
  { label: 'Mið', value: 'wed', day: 2 },
  { label: 'Fim', value: 'thu', day: 3 },
  { label: 'Fös', value: 'fri', day: 4 },
  { label: 'Lau', value: 'sat', day: 5 },
  { label: 'Sun', value: 'sun', day: 6 },
]

export const redDays: {
  label: string
  type: RedDaysValue['type']
}[] = [
  { label: 'Alltaf', type: 'all' },
  { label: 'Eftir plani', type: 'yes' },
  { label: 'Aldrei', type: 'no' },
]

export const durations: {
  label: string
  type: StatusDuration['type']
}[] = [
  { label: 'ótiltekin', type: 'indefinite' },
  { label: 'til og með', type: 'until' },
]
