import {
  AuthError,
  isSignInWithEmailLink,
  onAuthStateChanged,
  signInWithEmailLink as fbSignInWithEmailLink,
  User,
} from 'firebase/auth'
import { doc, onSnapshot } from 'firebase/firestore'
import * as React from 'react'
import { auth, firestore } from '..'
import { UserMeta } from '../../model/user-meta'
import { UserClaims } from '../../model/user-meta/user-claims'
import { Fetchable } from '../../utils/type'
import { Collections } from '../firestore/collections'
import { parseUserMetaSnapshot } from '../firestore/user-meta/parse-user-meta-snapshot'
import { getClaims } from './get-claims'

export type UserState =
  | { type: 'not set' }
  | { type: 'logged in'; user: User }
  | { type: 'not logged in' }
  | { type: 'signing in with email link' }
  | {
      type: 'signing in with email link but email missing or already logged in'
    }
  | { type: 'signing in with email link but email missing' }
  | { type: 'error'; error: AuthError }

// Get the email if available. This should be available if the user completes
// the flow on the same device where they started it.
const emailForSignIn = window.localStorage.getItem('emailForSignIn')
const initialUserState: UserState = isSignInWithEmailLink(
  auth,
  window.location.href
)
  ? emailForSignIn === null
    ? {
        type: 'signing in with email link but email missing or already logged in',
      }
    : { type: 'signing in with email link' }
  : { type: 'not set' }
let claimsRefreshTime: Date | undefined = undefined

export function useAuthState() {
  const [userState, setUserState] = React.useState<UserState>(initialUserState)
  const [claims, setClaims] = React.useState<Fetchable<UserClaims>>({
    type: 'not fetched',
  })
  const [userMeta, setUserMeta] = React.useState<Fetchable<UserMeta, Error>>({
    type: 'not fetched',
  })
  React.useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      if (emailForSignIn !== null) {
        // The client SDK will parse the code from the link for you.
        signInWithEmailLink(emailForSignIn).catch(function (error) {
          setUserState({ type: 'error', error })
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        })
      }
    }
    return onAuthStateChanged(
      auth,
      async (user) => {
        if (
          user === null &&
          (userState.type === 'signing in with email link' ||
            userState.type === 'signing in with email link but email missing')
        ) {
          return
        }
        if (
          user === null &&
          userState.type ===
            'signing in with email link but email missing or already logged in'
        ) {
          setUserState({ type: 'signing in with email link but email missing' })
          return
        }
        if (user === null) {
          setUserState({ type: 'not logged in' })
        } else if (user !== null) {
          setUserState({ type: 'logged in', user })
          setClaims({ type: 'fetching' })
          const claims = await getClaims(user)
          if (claims) {
            setClaims({ type: 'fetched', data: claims })
          } else {
            setClaims({ type: 'does not exist' })
          }
        }
      },
      (error) => {
        setUserState({ type: 'error', error: error as AuthError })
      }
    )
  }, [])
  React.useEffect(() => {
    if (userState.type !== 'logged in') {
      return () => {}
    }
    if (userMeta.type === 'fetched') {
      setUserMeta({
        type: 'refreshing',
        data: userMeta.data,
      })
    } else {
      setUserMeta({
        type: 'fetching',
      })
    }
    return onSnapshot(
      doc(firestore, Collections.UserMetas, userState.user.uid),
      (doc) => {
        if (doc.exists()) {
          const m = parseUserMetaSnapshot(doc)
          console.log('checking if should refresh claims', {
            claimsRefreshTime,
            m,
          })
          if (
            (claimsRefreshTime === undefined &&
              m.claimsRefreshTime !== undefined) ||
            (claimsRefreshTime !== undefined &&
              m.claimsRefreshTime !== undefined &&
              claimsRefreshTime.getTime() !== m.claimsRefreshTime.toMillis())
          ) {
            setClaims({ type: 'fetching' })
            getClaims(userState.user, { forceRefresh: true }).then((claims) => {
              if (claims) {
                setClaims({ type: 'fetched', data: claims })
              } else {
                setClaims({ type: 'does not exist' })
              }
            })
          }
          claimsRefreshTime = m.claimsRefreshTime
            ? m.claimsRefreshTime.toDate()
            : m.claimsRefreshTime
          setUserMeta({ type: 'fetched', data: m })
        } else {
          setUserMeta({ type: 'does not exist' })
        }
      },
      (error) => {
        setUserMeta({ type: 'fetched failed', error })
      }
    )
  }, [userState])
  return {
    userState,
    setUserState,
    claims,
    userMeta,
  }
}

export function signInWithEmailLink(emailForSignIn: string) {
  return fbSignInWithEmailLink(auth, emailForSignIn, window.location.href).then(
    function () {
      window.history.replaceState(null, '', window.location.pathname)
      // Clear email from storage.
      window.localStorage.removeItem('emailForSignIn')
    }
  )
}
