import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'
import { Link, LinkGetProps, Match, RouteComponentProps } from '@reach/router'
import { motion } from 'framer-motion'
import { IconButton } from 'ignitic/dist/core/icon-button'
import { linkStyles } from 'ignitic/dist/core/link'
import { Span } from 'ignitic/dist/core/text'
import { useUUID } from 'ignitic/dist/hooks/use-uuid'
import { Blanket } from 'ignitic/dist/popups/blanket'
import { display } from 'ignitic/dist/styles/display'
import { flex } from 'ignitic/dist/styles/flex'
import { padding } from 'ignitic/dist/styles/spacing'
import { Surface } from 'ignitic/dist/surfaces/surface'
import { cn } from 'itils/dist/misc/cn'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import css from './app-bar.css'
import { SubBar } from './sub-bar'

export type SiteMap = {
  to: string
  label: string
  active: 'current' | 'partial'
  subTreeMatch: string
  subTree: {
    to: string
    label: string
  }[]
}[]

type Props = {
  siteMap: SiteMap
  children: React.ReactNode
} & RouteComponentProps

export function AppBar(props: Props) {
  const [showMenu, setShowMenu] = React.useState(false)
  const asideMenuId = useUUID()
  return (
    <>
      <Surface
        className={cn(
          display.flex,
          flex.row,
          flex.alignCenter,
          padding.smX,
          css.appBar
        )}
        color="neutral"
      >
        <IconButton
          className={css.menuButton}
          aria-label={showMenu ? 'Loka menu' : 'Opna menu'}
          onClick={() => setShowMenu((prev) => !prev)}
          aria-controls={asideMenuId}
        >
          {showMenu ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
        {props.siteMap.map((l) => (
          <Link
            key={l.to}
            to={l.to}
            className={cn(linkStyles.neutral, css.navLink)}
            getProps={
              l.active == 'current'
                ? currentLink(css.navLink)
                : partialCurrentLink(css.navLink)
            }
          >
            {l.label}
          </Link>
        ))}
        {props.siteMap.map((l) => (
          <Match key={l.to} path={l.subTreeMatch}>
            {(p) =>
              p.match ? (
                <>
                  <Link
                    to={l.to}
                    className={cn(linkStyles.primary, css.crumbLink)}
                  >
                    {l.label}
                  </Link>
                  {l.subTree.map((s) => (
                    <Match key={s.to} path={s.to}>
                      {(m) =>
                        m.match ? (
                          <>
                            <Span className={css.crumbSpan}> / </Span>
                            <Link
                              to={s.to}
                              className={cn(linkStyles.primary, css.crumbLink)}
                            >
                              {s.label}
                            </Link>
                          </>
                        ) : null
                      }
                    </Match>
                  ))}
                </>
              ) : null
            }
          </Match>
        ))}
        {props.children}
      </Surface>
      {props.siteMap.map((l) => (
        <Match key={l.to} path={l.subTreeMatch}>
          {(p) => {
            console.log('subbar', { ...p, path: `${l.to}/*` })
            return p.match && l.subTree.length > 0 ? (
              <SubBar links={l.subTree} />
            ) : null
          }}
        </Match>
      ))}
      {ReactDOM.createPortal(
        <>
          <Blanket
            className={css.blanket}
            show={showMenu}
            onClick={() => setShowMenu(false)}
          />
          <motion.aside
            id={asideMenuId}
            className={css.asideMenu}
            role="complementary"
            aria-hidden={!showMenu}
            transition={{ type: 'tween', duration: 0.15 }}
            variants={{
              visible: { x: 0 },
              hidden: { x: '-15rem' },
            }}
            initial="hidden"
            animate={showMenu ? 'visible' : 'hidden'}
          >
            <Surface
              className={cn(
                display.flex,
                flex.col,
                flex.spacingMd,
                css.asideMenuContentContainer
              )}
              color="neutral"
            >
              <ul className={cn(display.flex, flex.col, flex.spacingMd)}>
                {props.siteMap.map((i) => (
                  <li key={i.to}>
                    <Link
                      onClick={() => setShowMenu(false)}
                      className={cn(linkStyles.neutral)}
                      getProps={
                        i.active == 'current'
                          ? currentLink()
                          : partialCurrentLink()
                      }
                      to={i.to}
                    >
                      {i.label}
                    </Link>
                    {i.subTree.length > 0 && (
                      <ul
                        className={cn(
                          display.flex,
                          flex.col,
                          flex.spacingSm,
                          css.subTree
                        )}
                      >
                        {i.subTree.map((t) => (
                          <li key={t.to}>
                            <Link
                              onClick={() => setShowMenu(false)}
                              className={linkStyles.neutral}
                              getProps={currentLink()}
                              to={t.to}
                            >
                              {t.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </Surface>
          </motion.aside>
        </>,
        document.body
      )}
    </>
  )
}

function activeProps(className?: string) {
  return {
    className: cn(linkStyles.primary, css.active, className),
  }
}
export function currentLink(className?: string) {
  return ({ isCurrent }: LinkGetProps) =>
    isCurrent ? activeProps(className) : {}
}
export function partialCurrentLink(className?: string) {
  return ({ isPartiallyCurrent }: LinkGetProps) =>
    isPartiallyCurrent ? activeProps(className) : {}
}
