import { QueryConstraint } from 'firebase/firestore'
import { Collections } from '../collections'
import { useCollection } from '../utils/use-collection'
import { parseCareOrderSnapshot } from './parse-care-order-snapshot'

export function useCareOrders(
  queryConstraints: QueryConstraint[],
  deps: React.DependencyList = []
) {
  return useCollection({
    path: Collections.CareOrders,
    queryConstraints,
    parseDocument: parseCareOrderSnapshot,
    deps,
  })
}
