import Menu from '@material-ui/core/Menu'
import MoreVert from '@material-ui/icons/MoreVert'
import { IconButton } from 'ignitic/dist/core/icon-button'
import { useUUID } from 'ignitic/dist/hooks/use-uuid'
import * as React from 'react'

type Props = {
  children: React.ReactNode
}

export function MoreVertMenu(props: Props) {
  const [open, setOpen] = React.useState(false)
  return <MoreVertMenuUncontrolled {...props} open={open} setOpen={setOpen} />
}

export function MoreVertMenuUncontrolled(
  props: Props & { open: boolean; setOpen: (open: boolean) => void }
) {
  const ref = React.useRef<HTMLButtonElement>(null)
  const id = useUUID()
  return (
    <React.Fragment>
      <IconButton
        ref={ref}
        aria-label="Aðgerðir"
        aria-owns={props.open ? id : undefined}
        aria-haspopup="true"
        onClick={() => props.setOpen(true)}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id={id}
        anchorEl={ref.current}
        open={props.open}
        onClose={() => props.setOpen(false)}
      >
        {props.children}
      </Menu>
    </React.Fragment>
  )
}
