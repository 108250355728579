import TextField from '@material-ui/core/TextField'
import { FirebaseError } from 'firebase/app'
import { Button } from 'ignitic/dist/core/button'
import { H1, H2, P } from 'ignitic/dist/core/text'
import { Paper } from 'ignitic/dist/surfaces/paper'
import * as React from 'react'
import { signInWithEmailLink } from '../../../firebase/auth/use-auth-state'
import { useTextInput } from '../../../hooks/useTextInput'
import { isEmail } from '../../../utils/isEmail'
import { getAuthErrorMessage } from './get-auth-error-message'
import * as css from './login.shared.css'
import { SkutanLogo } from './skutan-logo'

export const EmailForSignInPage = () => {
  const [email, , emailInputProps] = useTextInput('')
  const [state, setState] = React.useState<
    | { type: 'input' }
    | { type: 'logging in' }
    | { type: 'error'; error: FirebaseError }
  >({ type: 'input' })
  console.log('EmailForSignInPage render', state)
  return (
    <main className={css.main}>
      <SkutanLogo />
      <H1 variant="h3">Pöntunarkerfi fyrir heimaþjónustu bæjarfélaga</H1>
      <Paper className={css.paper}>
        <H2 color="primary" variant="h4" marginBottom>
          Staðfestið póstfang
        </H2>
        {(state.type === 'input' || state.type === 'logging in') && (
          <form
            className={css.form}
            noValidate
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault()
              setState({ type: 'logging in' })
              signInWithEmailLink(email).catch((error) => {
                setState({ type: 'error', error })
              })
            }}
          >
            <TextField
              autoFocus
              disabled={state.type !== 'input'}
              fullWidth
              label="Email"
              {...emailInputProps}
              variant="standard"
              helperText="Þessi innskráningar tengill var ekki stofnaður í gegnum þennan vafra. Því þarf að staðfesta póstfang."
            />
            <Button
              disabled={state.type !== 'input' || !isEmail(email)}
              type="submit"
              variant="filled"
            >
              Innskrá
            </Button>
          </form>
        )}
        {state.type === 'error' && (
          <P color="secondary">{getAuthErrorMessage(state.error)}</P>
        )}
        {state.type === 'error' && state.error.code === 'auth/invalid-email' && (
          <Button
            className={css.tryAgainButton}
            variant="filled"
            onClick={() => setState({ type: 'input' })}
          >
            Reyna aftur
          </Button>
        )}
      </Paper>
    </main>
  )
}
