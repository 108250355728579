import * as Sentry from '@sentry/browser'
import { User } from 'firebase/auth'
import { Timestamp } from 'firebase/firestore'
import { H1, P } from 'ignitic/dist/core/text'
import * as React from 'react'
import { addErrorBoundaryLog } from '../../firebase/firestore/logs/add-log'
import * as css from './app-error-boundary.css'

type Props = {
  user?: User
  instanceId: string
}
type State = Readonly<{
  error?: unknown
}>

const initialState: State = {
  error: undefined,
}

export class AppErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = initialState
  }

  componentDidCatch?(error: Error, errorInfo: React.ErrorInfo) {
    console.log({ error, errorInfo, NODE_ENV: process.env.NODE_ENV })

    this.setState({ error })
    addErrorBoundaryLog({
      instanceId: this.props.instanceId,
      timestamp: Timestamp.now(),
      type: 'error boundary log',
      level: 'error',
      userAgent: window.navigator.userAgent,
      user: this.props.user
        ? {
            type: 'user',
            email: this.props.user.email || '',
            uid: this.props.user.uid,
          }
        : { type: 'unauthenticated' },
      error: {
        message: error.message,
        name: error.name,
        stack: error.stack,
      },
      errorInfo: {
        componentStack: errorInfo.componentStack,
      },
      env: process.env.NODE_ENV,
    })
    if (process.env.SENTRY_DSN !== undefined) {
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, (errorInfo as any)[key])
        })
        scope.setExtra('instanceId', this.props.instanceId)
        if (this.props.user) {
          scope.setUser({
            email: this.props.user.email || '',
            id: this.props.user.uid,
          })
        }
        Sentry.captureException(error)
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.error === undefined && this.props.children}
        {this.state.error && (
          <main className={css.container}>
            <H1 variant="h5">Óvænt villa hefur komið upp</H1>
            <P>
              Villan hefur verið skráð og verður vonandi löguð á næstu dögum.
            </P>
          </main>
        )}
      </React.Fragment>
    )
  }
}
