import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import { User } from 'firebase/auth'
import { Button } from 'ignitic/dist/core/button'
import { H3 } from 'ignitic/dist/core/text'
import { useUUID } from 'ignitic/dist/hooks/use-uuid'
import { display } from 'ignitic/dist/styles/display'
import { flex } from 'ignitic/dist/styles/flex'
import { cn } from 'itils/dist/misc/cn'
import * as React from 'react'
import { addCareReceiver } from '../../../firebase/firestore/care-receivers/add-care-receiver'
import { useTextInput } from '../../../hooks/useTextInput'
import { CareConfig } from '../../../model/care-config'
import { CareReceiver } from '../../../model/care-receiver'
import { CareReceiverStatus } from '../../../model/care-receiver-status'
import { defaultRepeatStatus } from '../../../model/care-receiver-status/default'
import { newCareReceiverStatuses } from '../../../model/care-receiver-status/values'
import { Customer } from '../../../model/customer'
import { Route } from '../../../model/route'
import { SelectMultipleStringsField } from '../../select-multiple-strings-field'
import { SelectStringField } from '../../select-string-field'
import { SelectCustomerField } from '../../selects/select-customer-field'
import { SelectRouteField } from '../../selects/select-route-field'
import {
  getDeliveryLocFieldValidity,
  getKennitalaFieldValidity,
  getNameFieldValidity,
  isValid,
} from '../care-receiver-form-utils'
import { StatusFormGroup } from '../status-form-group'
import * as css from './new-care-receiver-dialog.css'

type Props = {
  open: boolean
  onClose: () => void
  id: string
  customers?: Customer[]
  /**
   * All care receivers of the current customer
   */
  careReceivers: CareReceiver[]
  defaultCustomer?: { id: string; name: string }
  careConfig: CareConfig
  routes?: Route[]
  user: User
}

type NewReceiverFormBlurred = {
  name: boolean
  deliveryLocation: boolean
  kennitala: boolean
  customer: boolean
}
const noneBlurred: NewReceiverFormBlurred = {
  name: false,
  deliveryLocation: false,
  kennitala: false,
  customer: false,
}
const allBlurred: NewReceiverFormBlurred = {
  name: true,
  deliveryLocation: true,
  kennitala: true,
  customer: true,
}

export function NewCareReceiverDialog(props: Props) {
  const titleId = useUUID()
  const [name, setName, nameInputProps] = useTextInput('')
  const [deliveryLocation, setDeliveryLocation, deliveryLocationInputProps] =
    useTextInput('')
  const [kennitala, setKennitala, kennitalaInputProps] = useTextInput('')
  const [customerNote, setCustomerNote, customerNoteInputProps] =
    useTextInput('')
  const [phone1, setPhone1, phone1InputProps] = useTextInput('')
  const [phone2, setPhone2, phone2InputProps] = useTextInput('')
  const [relativePhone, setRelativePhone, relativePhoneInputProps] =
    useTextInput('')
  const [careProviderNote, setCareProviderNote, careProviderNoteInputProps] =
    useTextInput('')
  const [blurred, setBlurred] = React.useState(noneBlurred)
  const [selectedCustomer, setSelectedCustomer] = React.useState<
    undefined | Customer
  >(undefined)
  const [selectedRoute, setSelectedRoute] = React.useState<null | Route>(null)
  const defaultFoodType =
    props.careConfig.foodTypes.find((i) => i === 'Almennt fæði') ||
    props.careConfig.foodTypes[0]
  const [selectedFoodType, setSelectedFoodType] =
    React.useState(defaultFoodType)
  const [selectedFoodResctrictions, setSelectedFoodRestrictions] =
    React.useState<string[]>([])
  const [status, setStatus] =
    React.useState<CareReceiverStatus>(defaultRepeatStatus)

  function resetState() {
    setName('')
    setDeliveryLocation('')
    setKennitala('')
    setBlurred(noneBlurred)
    setSelectedCustomer(undefined)
    setCustomerNote('')
    setCareProviderNote('')
    setSelectedFoodType(defaultFoodType)
    setSelectedFoodRestrictions([])
    setPhone1('')
    setPhone2('')
    setRelativePhone('')
    setSelectedRoute(null)
    setStatus(defaultRepeatStatus)
  }
  function onClose() {
    resetState()
    props.onClose()
  }
  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    let customerId = ''
    let customerName = ''
    if (!selectedCustomer && !props.defaultCustomer) {
      return
    }
    if (selectedCustomer) {
      customerName = selectedCustomer.name
      customerId = selectedCustomer.id
    }
    if (props.defaultCustomer) {
      customerName = props.defaultCustomer.name
      customerId = props.defaultCustomer.id
    }
    addCareReceiver({
      name,
      deliveryLocation,
      kennitala,
      customerId,
      customerName,
      customerNote,
      careProviderNote,
      foodType: selectedFoodType,
      foodRestrictions: selectedFoodResctrictions,
      status,
      phone1,
      phone2,
      relativePhone,
      route: selectedRoute && {
        id: selectedRoute.id,
        name: selectedRoute.name,
      },
      createdBy: {
        type: 'user',
        uid: props.user.uid,
        email: props.user.email as string,
      },
    }).catch((err: any) => console.error('addCareReceiver', err))
    onClose()
  }
  const nameFieldValidity = getNameFieldValidity(name)
  const deliveryLocFieldValidity = getDeliveryLocFieldValidity(deliveryLocation)
  const kennitalaFieldValidity = getKennitalaFieldValidity(
    kennitala,
    props.careReceivers
      .filter((i) => {
        if (selectedCustomer) {
          return i.customerId == selectedCustomer.id
        }
        if (props.defaultCustomer) {
          return i.customerId == props.defaultCustomer.id
        }
        return false
      })
      .map((i) => i.kennitala)
  )
  const isSubmitButtonEnabled = isValid([
    nameFieldValidity,
    deliveryLocFieldValidity,
    kennitalaFieldValidity,
  ])

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={onClose}
      id={props.id}
      aria-labelledby={titleId}
    >
      <DialogTitle
        style={{ paddingBottom: 0 }}
        id={titleId}
        className={cn(display.flex, flex.row, flex.justifyCenter)}
        classes={{ root: css.title }}
      >
        <div>Stofna nýjan viðtakanda</div>
      </DialogTitle>
      <form noValidate autoComplete="off" onSubmit={onSubmit}>
        <DialogContent
          style={{ minWidth: 600 }}
          className={cn(
            display.flex,
            flex.col,
            flex.spacingSm,
            css.content,
            flex.alignCenter
          )}
        >
          <H3 color="neutral" variant="p">
            Grunnupplýsingar
          </H3>
          <TextField
            label="Nafn"
            required
            autoFocus
            {...nameInputProps}
            error={blurred.name && !nameFieldValidity.valid}
            onBlur={() => setBlurred({ ...blurred, name: true })}
            onFocus={() => setBlurred({ ...blurred, name: false })}
            helperText={blurred.name ? nameFieldValidity.text : ' '}
          />
          <TextField
            label="Heimilisfang"
            required
            {...deliveryLocationInputProps}
            error={blurred.deliveryLocation && !deliveryLocFieldValidity.valid}
            onBlur={() => setBlurred({ ...blurred, deliveryLocation: true })}
            onFocus={() => setBlurred({ ...blurred, deliveryLocation: false })}
            helperText={
              blurred.deliveryLocation ? deliveryLocFieldValidity.text : ' '
            }
          />
          <TextField
            label="Kennitala"
            required
            {...kennitalaInputProps}
            error={blurred.kennitala && !kennitalaFieldValidity.valid}
            onBlur={() => setBlurred({ ...blurred, kennitala: true })}
            onFocus={() => setBlurred({ ...blurred, kennitala: false })}
            helperText={blurred.kennitala ? kennitalaFieldValidity.text : ' '}
          />
          {props.customers && (
            <SelectCustomerField
              label="Viðskiptavinur"
              required
              error={blurred.customer && selectedCustomer === undefined}
              value={selectedCustomer !== undefined ? selectedCustomer.id : ''}
              setCustomer={setSelectedCustomer}
              onBlur={() => setBlurred({ ...blurred, customer: true })}
              onFocus={() => setBlurred({ ...blurred, customer: false })}
              customers={props.customers}
              helperText={
                blurred.customer && selectedCustomer === undefined
                  ? 'Vantar viðskiptavin'
                  : ' '
              }
            />
          )}
          <TextField
            label="Athugasemd"
            {...customerNoteInputProps}
            helperText=" "
          />
          <SelectStringField
            label="Tegund"
            required
            value={selectedFoodType}
            values={props.careConfig.foodTypes}
            onChange={setSelectedFoodType}
          />
          <SelectMultipleStringsField
            label="Sérþarfir"
            value={selectedFoodResctrictions}
            values={props.careConfig.foodRestrictions}
            onChange={(values) => setSelectedFoodRestrictions(values)}
            onDelete={(value) =>
              setSelectedFoodRestrictions(
                selectedFoodResctrictions.filter((r) => r !== value)
              )
            }
          />
          <TextField label="Símanúmer 1" {...phone1InputProps} helperText=" " />
          <TextField label="Símanúmer 2" {...phone2InputProps} helperText=" " />
          <TextField
            label="Símanúmer aðstandanda"
            {...relativePhoneInputProps}
            helperText=" "
          />
          {props.routes && (
            <React.Fragment>
              <TextField
                label="Upplýsingar f. bílstj."
                {...careProviderNoteInputProps}
                helperText=" "
              />
              <SelectRouteField
                label="Akstur"
                value={selectedRoute !== null ? selectedRoute.id : ''}
                setRoute={setSelectedRoute}
                routes={props.routes}
              />
            </React.Fragment>
          )}
          <H3 color="neutral" variant="p">
            Staða
          </H3>
          <StatusFormGroup
            status={status}
            setStatus={setStatus}
            statuses={newCareReceiverStatuses}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <div
            style={{ width: 600 }}
            className={cn(
              display.flex,
              flex.row,
              flex.justifyEnd,
              flex.spacingMd
            )}
          >
            <Button
              onClick={onClose}
              type="button"
              variant="outlined"
              color="secondary"
            >
              Hætta við
            </Button>
            <div onClick={() => setBlurred(allBlurred)}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                disabled={!isSubmitButtonEnabled}
              >
                Vista
              </Button>
            </div>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  )
}
