import { FireTimestamp } from '../../firebase/firestore/utils/types'
import { Omit, Overwrite } from '../../utils/type'
import { ByUser, EventBy, NewEventByDocument } from '../event-by'

export function careOrderDeliveryToText(d: CareOrderDelivery) {
  if (d.type == 'not set') {
    return ''
  }
  return d.name
}

export type CareOrderType = 'manual' | 'generated'
export type CareOrderDelivery =
  | { type: 'not set' }
  | { type: 'route'; id: string; name: string; setManually?: boolean }

export type DeliverySetStatus =
  | 'delivered'
  | 'did not anwser'
  | 'says shouldnt receive'
  | 'in hospital'
  | 'wants to make changes'
  | 'other see comment'

export interface CareOrder {
  id: string
  type: CareOrderType
  receiver: {
    id: string
    name: string
    deliveryLocation: string
    kennitala: string
  }
  customer: {
    id: string
    name: string
  }
  /**
   * Only day part is relevant. This property should not include any time information.
   */
  day: FireTimestamp
  dayId: string
  delivery: CareOrderDelivery
  deliveryStatus:
    | { type: 'not set' }
    | {
        type: 'set'
        status: DeliverySetStatus
        at?: FireTimestamp
        by?: ByUser
        comment: string
        notification: { type: 'not sent' }
      }
  /**
   * Should be either 0 or a positive integer.
   * If it is zero it means that an order was created with non-zero
   * portions but later changed to zero.
   */
  portions: number
  careProviderNote: string
  customerNote: string
  /**
   * One of the strings defined in careConfig.foodTypes
   */
  foodType: string
  /**
   * Zero or more of the strings defined in careConfig.foodRestrictions
   */
  foodRestrictions: string[]
  created: EventBy
  /**
   * Undefined if this document has never been modified.
   */
  lastModified?: EventBy
}

export type CareOrderDocument = Omit<CareOrder, 'id'>

export type NewCareOrderDocument = Overwrite<
  Omit<CareOrder, 'id'>,
  {
    created: NewEventByDocument
  }
>
export type UpdateCareOrderDocument = {
  lastModified: NewEventByDocument
} & Partial<Omit<CareOrder, 'id' | 'created'>>

export function createCareOrderId(order: NewCareOrderDocument) {
  return `${order.receiver.id}-${order.dayId}`
}

export function deliveryStatusToText(status: CareOrder['deliveryStatus']) {
  if (status.type == 'not set') {
    return ''
  }
  return deliverySetStatusToText(status.status)
}

export function deliverySetStatusToText(status: DeliverySetStatus) {
  switch (status) {
    case 'delivered':
      return 'Afhent'
    case 'did not anwser':
      return 'Svaraði ekki'
    case 'says shouldnt receive':
      return 'Segist ekki eiga að fá mat'
    case 'in hospital':
      return 'Á spítala'
    case 'wants to make changes':
      return 'Vill gera breytingar, hafið samband'
    case 'other see comment':
      return 'Annað, sjá athugasemd'
    default:
      return ''
  }
}

export const deliveryStatuses: DeliverySetStatus[] = [
  'delivered',
  'did not anwser',
  'says shouldnt receive',
  'in hospital',
  'wants to make changes',
  'other see comment',
]
