.fb2205 {
  position: relative;
  height: 1.5rem;
  width: 3rem;
  min-height: 1.5rem;
  min-width: 3rem;
}
.fb2205 .ccced6 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
}
.bff657 {
  opacity: 0.5;
  position: absolute;
  height: 1rem;
  width: 3rem;
  top: 0.25rem;
  left: 0;
  border-radius: 0.5rem;
  background: var(--neutral-light-on-background-color);
  transition: background-color 0.5s ease;
}
.a842c9 {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  position: absolute;
  left: 0;
  top: 0;
  background: var(--neutral-light-on-background-color);
  transition: background-color 0.2s ease, transform 0.1s ease-out;
}
.e85668 .a842c9 {
  transform: translateX(1.5rem);
}

.adcf21.e85668 .bff657,
.adcf21.e85668 .a842c9 {
  background: var(--neutral-on-background-color);
}
.a92d74.e85668 .bff657,
.a92d74.e85668 .a842c9 {
  background: var(--default-color);
}
.b764bf.e85668 .bff657,
.b764bf.e85668 .a842c9 {
  background: var(--primary-color);
}
.d7ffdd.e85668 .bff657,
.d7ffdd.e85668 .a842c9 {
  background: var(--secondary-color);
}
