import { Redirect, Router } from '@reach/router'
import { User } from 'firebase/auth'
import * as React from 'react'
import { Main } from '../../components/main'
import { CustomerManagerClaims } from '../../model/user-meta/user-claims'
import * as css from './customer-shell.css'

const CareIndexPage = React.lazy(() => import('./routes/care/care-index-page'))
const CareReportPage = React.lazy(
  () => import('./routes/care/care-report-page')
)
const CareDayReportPage = React.lazy(
  () => import('./routes/care/care-day-report')
)

type Props = {
  user: User
  claims: CustomerManagerClaims
}

export function CustomerShell({ user, claims }: Props) {
  return (
    <Main className={css.main} withAppBar>
      <React.Suspense fallback={null}>
        <Router>
          <Redirect from="/" to="/heimathjonusta/listi" noThrow />
          <Redirect from="/heimathjonusta" to="/heimathjonusta/listi" noThrow />
          <CareIndexPage
            path="/heimathjonusta/listi"
            user={user}
            claims={claims}
          />
          <CareReportPage
            path="/heimathjonusta/samtolur"
            user={user}
            claims={claims}
          />
          <CareDayReportPage
            path="/heimathjonusta/yfirlit-dags"
            user={user}
            claims={claims}
          />
        </Router>
      </React.Suspense>
    </Main>
  )
}
