import { compareStringIS } from 'itils/dist/compare/compare-string-is'

export function foodResctrictionsToString(arr: string[]) {
  const nots = arr
    .filter((i) => i.startsWith('ekki'))
    .map((i) => i.replace('ekki ', ''))
    .sort(compareStringIS)
  const others = arr.filter((i) => !i.startsWith('ekki')).sort(compareStringIS)
  return `${others.join(', ')}${
    others.length > 0 && nots.length > 0 ? ', ' : ''
  }${nots.length > 0 ? `ekki ${nots.join(', ')}` : ''}`
}

export function foodKind(foodType: string, foodRestrictions: string[]) {
  const restrStr = foodResctrictionsToString(foodRestrictions)
  if (restrStr == '') {
    return foodType
  } else if (foodType.toLowerCase() == 'almennt fæði') {
    return restrStr
  } else {
    return `${foodType}, ${restrStr}`
  }
}

export function foodKindSkipAlmennt(
  foodType: string,
  foodRestrictions: string[]
) {
  const kind = foodKind(foodType, foodRestrictions)
  return kind.toLowerCase() == 'almennt fæði' ? '' : kind
}
