import { padding } from 'ignitic/dist/styles/spacing'
import { cn } from 'itils/dist/misc/cn'
import * as React from 'react'
import * as css from './main.css'

type Props = {
  children: React.ReactNode
  withAppBar: boolean
  className?: string
}

export function Main(props: Props) {
  return (
    <main
      className={cn(
        css.main,
        padding.md,
        padding.print_none,
        {
          [css.withAppBar]: props.withAppBar,
        },
        props.className
      )}
    >
      {props.children}
    </main>
  )
}
