import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import isSameDay from 'date-fns/isSameDay'
import startOfDay from 'date-fns/startOfDay'
import { User } from 'firebase/auth'
import { Button } from 'ignitic/dist/core/button'
import { P } from 'ignitic/dist/core/text'
import { useUUID } from 'ignitic/dist/hooks/use-uuid'
import { display } from 'ignitic/dist/styles/display'
import { flex } from 'ignitic/dist/styles/flex'
import { cn } from 'itils/dist/misc/cn'
import * as React from 'react'
import {
  AddCareOrderItem,
  batchAddOrUpdateCareOrders,
  UpdateCareOrderItem,
} from '../../firebase/firestore/care-orders/batch-add-or-update-care-orders'
import { CareOrder } from '../../model/care-order'
import { CareReceiver } from '../../model/care-receiver'
import { Day } from '../../model/day/day'
import { PortionsItem } from '../../model/types/portions-item'
import { UserClaims } from '../../model/user-meta/user-claims'
import { filterUndefined, immutableUpdateItem } from '../../utils/array'
import { isWeekendOrHoliday } from '../../utils/date'
import { formatIs } from '../../utils/date-fns'

export type EditPortionsDialogPropsState =
  | {
      type: 'open'
      receiver: CareReceiver
      portions: PortionsItem[]
    }
  | { type: 'closed' }

type Props = {
  onClose: () => void
  id: string
  propsState: EditPortionsDialogPropsState
  user: User
  claims: UserClaims
  presentAndFutureDays: Day[]
}

export function EditPortionsDialog({
  propsState,
  onClose,
  id,
  user,
  claims,
  presentAndFutureDays,
}: Props) {
  const titleId = useUUID()
  const [state, setState] = React.useState<PortionsItem[]>([])

  React.useEffect(() => {
    setState(propsState.type == 'open' ? propsState.portions : [])
  }, [propsState])

  const notOpenPresentAndFutureDays = presentAndFutureDays.filter(
    (i) => i.careDayStatus.type != 'open'
  )

  function onDialogClose() {
    onClose()
    resetState()
  }

  function resetState() {
    setState([])
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!state.some((i) => i.source.type == 'edit')) {
      return
    }
    const items = filterUndefined(
      state.map<UpdateCareOrderItem | AddCareOrderItem | undefined>((i) => {
        if (i.source.type != 'edit' || propsState.type != 'open') {
          return undefined
        }
        if (i.source.order) {
          const result: UpdateCareOrderItem = {
            type: 'update',
            id: i.source.order.id,
            update: {
              portions: i.portions,
              type: 'manual',
            },
          }
          return result
        }
        const result: AddCareOrderItem = {
          type: 'add',
          add: {
            type: 'manual',
            receiver: {
              id: propsState.receiver.id,
              name: propsState.receiver.name,
              deliveryLocation: propsState.receiver.deliveryLocation,
              kennitala: propsState.receiver.kennitala,
            },
            customer: {
              id: propsState.receiver.customerId,
              name: propsState.receiver.customerName,
            },
            day: startOfDay(i.day.date),
            delivery:
              propsState.receiver.delivery.type == 'not set'
                ? { type: 'not set' }
                : isWeekendOrHoliday(i.day.date)
                ? {
                    type: 'route',
                    id: propsState.receiver.delivery.weekendsAndHolidays.id,
                    name: propsState.receiver.delivery.weekendsAndHolidays.name,
                  }
                : {
                    type: 'route',
                    id: propsState.receiver.delivery.workday.id,
                    name: propsState.receiver.delivery.workday.name,
                  },
            portions: i.portions,
            careProviderNote: propsState.receiver.careProviderNote,
            customerNote: propsState.receiver.customerNote,
            foodType: propsState.receiver.foodType,
            foodRestrictions: propsState.receiver.foodRestrictions,
          },
        }
        return result
      })
    )
    batchAddOrUpdateCareOrders(items, user).catch((err) =>
      console.error('edit portions error', err)
    )
    onClose()
  }
  return (
    <Dialog
      open={propsState.type == 'open'}
      onClose={onDialogClose}
      id={id}
      aria-labelledby={titleId}
    >
      {propsState.type == 'open' && (
        <React.Fragment>
          <DialogTitle style={{ paddingBottom: 0 }} id={titleId}>
            Breyta pöntun fyrir {propsState.receiver.name}
          </DialogTitle>
          <form noValidate autoComplete="off" onSubmit={onSubmit}>
            <DialogContent
              className={cn(display.flex, flex.col, flex.spacingMd)}
            >
              <div className={cn(display.flex, flex.row, flex.spacingMd)}>
                <InfoOutlined color="primary" />
                <div className={cn(display.flex, flex.col, flex.spacingMd)}>
                  <P>
                    Hér er hægt að breyta pöntun handvirkt. Handvirk breyting
                    hefur ekki áhrif á aðrar endurteknar pantanir.
                  </P>
                  {notOpenPresentAndFutureDays.length > 0 && (
                    <div className={cn(display.flex, flex.col)}>
                      <P>
                        Eftirfarandi dögum hefur verið lokað fyrir breytingum á
                        pöntunum:
                      </P>
                      <ul>
                        {notOpenPresentAndFutureDays.map((d) => (
                          <li key={d.id}>
                            <P>{formatIs(d.day.toDate(), 'PP')}</P>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className={cn(display.flex, flex.row, flex.spacingMd)}>
                {state.map((p, index) => {
                  const day = presentAndFutureDays.find((i) =>
                    isSameDay(i.day.toDate(), p.day.date)
                  )
                  const dayIsNotOpen = day && day.careDayStatus.type != 'open'
                  return (
                    <TextField
                      key={p.day.value}
                      label={p.day.label}
                      disabled={
                        ((p.day.type == 'past' || dayIsNotOpen) &&
                          claims.role != 'admin') ||
                        p.source.type == 'not fetched'
                      }
                      value={p.portions}
                      onChange={(event) => {
                        const value = parseInt(event.target.value, 10)
                        if (isNaN(value) || value < 0) {
                          return
                        }
                        const curr = propsState.portions.find(
                          (i) => i.day.day == p.day.day
                        )
                        if (curr == undefined) {
                          return
                        }
                        if (curr.portions == value) {
                          setState(immutableUpdateItem(state, curr, index))
                        } else {
                          const order: CareOrder | undefined =
                            curr.source.type == 'repeat' ||
                            curr.source.type == 'not fetched'
                              ? undefined
                              : curr.source.order
                          const update: PortionsItem = {
                            ...curr,
                            portions: value,
                            source: { type: 'edit', order },
                          }
                          setState(immutableUpdateItem(state, update, index))
                        }
                      }}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText=" "
                    />
                  )
                })}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={onDialogClose}
                type="button"
                variant="outlined"
                color="secondary"
              >
                Hætta við
              </Button>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                disabled={!state.some((i) => i.source.type == 'edit')}
              >
                Vista
              </Button>
            </DialogActions>
          </form>
        </React.Fragment>
      )}
    </Dialog>
  )
}
