.fd7806 {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  --cell-padding-top-bottom: var(--spacer);
  --cell-padding-right: var(--space-md);
  --cell-padding-left: var(--space-md);
  --header-cell-padding-top-bottom: calc(var(--spacer) * 1.5);
  --table-cell-font-size: var(--font-size-p);
  --table-cell-line-height: var(--line-height-p);
}
.fd7806.f8fdf9 {
  table-layout: fixed;
}
.fd7806.f93239,
.b64798.f93239 {
  --cell-padding-top-bottom: calc(var(--spacer) / 2);
}
.fd7806.f93239,
.a7c376.f93239 {
  --header-cell-padding-top-bottom: var(--spacer);
}
.fd7806.f93239 .cb259b:not(:first-child) {
  --cell-padding-left: var(--spacer);
}
.fd7806.f93239 .cb259b:not(:last-child) {
  --cell-padding-right: var(--spacer);
}
.fd7806.daa27b,
.a7c376.daa27b,
.b64798.daa27b {
  --cell-padding-top-bottom: 0;
  --header-cell-padding-top-bottom: 0;
  --cell-padding-right: calc(var(--spacer) * 1.5);
  --cell-padding-left: calc(var(--spacer) * 1.5);
}
.fd7806.f68a7c,
.fd7806 .cb259b.a7c376.f68a7c,
.fd7806 .cb259b.b64798.f68a7c {
  --cell-padding-top-bottom: 0;
  --header-cell-padding-top-bottom: 0;
  --cell-padding-right: 0;
  --cell-padding-left: 0;
}

.d61ba3 {
  vertical-align: middle;
}
.d61ba3.c75873:hover .cb259b {
  background: var(--neutral-hover-color-filled);
}

.cb259b {
  font-size: var(--table-cell-font-size);
  line-height: var(--table-cell-line-height);
  font-weight: 300;
  text-align: left;
  overflow: hidden;
}

.cb259b.ea89a5 {
  font-variant-numeric: tabular-nums;
}
@media screen {
  .cb259b.c8ab7e {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .cb259b.db784b {
    text-overflow: ellipsis;
  }
  .cb259b {
    box-shadow: inset 0px -1px 0px 0px var(--neutral-seperator-on-background-color);
  }
}
@media print {
  .cb259b {
    border-bottom: 1px solid var(--neutral-seperator-on-background-color);
    line-height: calc(var(--table-cell-line-height) - 1px);
  }
}
.cb259b.b16e31 {
  text-align: center;
}
.cb259b.c3f833 {
  text-align: right;
}
.cb259b.ad609f {
  text-align: justify;
}

.fd7806.e415f8 .cb259b {
  --table-cell-font-size: var(--font-size-p-small);
  --table-cell-line-height: var(--line-height-p-small);
}

.a7c376 {
  color: var(--disabled-color);
  padding: var(--header-cell-padding-top-bottom) var(--cell-padding-right)
    var(--header-cell-padding-top-bottom) var(--cell-padding-left);
}
.b64798 {
  padding: var(--cell-padding-top-bottom) var(--cell-padding-right)
    var(--cell-padding-top-bottom) var(--cell-padding-left);
}
.deac1e {
  color: inherit;
  width: 100%;
  border: none;
  background: transparent;
  font-size: inherit;
  line-height: inherit;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  outline-offset: -4px;
  padding: var(--header-cell-padding-top-bottom) var(--cell-padding-right)
    var(--header-cell-padding-top-bottom) var(--cell-padding-left);
  margin: calc(-1 * var(--header-cell-padding-top-bottom))
    calc(-1 * var(--cell-padding-right))
    calc(-1 * var(--header-cell-padding-top-bottom))
    calc(-1 * var(--cell-padding-left));
}
.deac1e > svg {
  flex-shrink: 0;
  display: inline-block;
  fill: currentColor;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 0;
  height: calc(var(--table-cell-font-size) + 0.1rem);
  width: calc(var(--table-cell-font-size) + 0.1rem);
}
.deac1e.fc0e50,
.deac1e.d70511 {
  color: var(--neutral-on-background-color);
}
.deac1e.fc0e50 > svg,
.deac1e.d70511 > svg {
  opacity: 1;
}
.deac1e.fc0e50 > svg {
  transform: rotate(180deg);
}
.cb259b.b16e31 .deac1e {
  justify-content: center;
}
.cb259b.c3f833 .deac1e {
  justify-content: flex-end;
}
