import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import {
  enableIndexedDbPersistence,
  initializeFirestore,
} from 'firebase/firestore'

export const firebaseApp = initializeApp({
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
})

export const firestore = initializeFirestore(firebaseApp, {
  ignoreUndefinedProperties: true,
})
export const auth = getAuth(firebaseApp)

enableIndexedDbPersistence(firestore, {})
  .then(() => {
    console.log('Multi-Tab Persistence', 'enabled')
  })
  .catch(function (err) {
    if (err.code == 'failed-precondition') {
      console.log(
        'Multi-Tab Persistence',
        'failed precondition.',
        'Multiple tabs open, persistence can only be enabled in one tab at a time.'
      )
    } else if (err.code == 'unimplemented') {
      console.log(
        'Multi-Tab Persistence',
        'The current browser does not support all of the features required to enable persistence'
      )
    }
  })
