import { addDays, isSameDay, isWeekend, subDays } from 'date-fns'

// import * as addDays from 'date-fns/addDays'
// import * as isSameDay from 'date-fns/isSameDay'
// import * as isWeekend from 'date-fns/isWeekend'
// import * as subDays from 'date-fns/subDays'

export type IcelandicDayType =
  | { type: 'normal' }
  | {
      type: 'red'
      name:
        | 'skírdagur'
        | 'annar í páskum'
        | 'sumardagurinn fyrsti'
        | 'alþjóðlegur frídagur verkafólks'
        | 'uppstigningardagur'
        | 'annar í hvítasunnu'
        | 'frídagur verslunarmanna'
        | 'annar í jólum'
    }
  | {
      type: 'big red'
      name:
        | 'nýársdagur'
        | 'föstudagurinn langi'
        | 'páskadagur'
        | 'hvítasunnudagur'
        | 'þjóðhátíðardagur Íslendinga'
        | 'aðfangadagur'
        | 'jóladagur'
        | 'gamlársdagur'
    }

export function getIcelandicDayType(date: Date): IcelandicDayType {
  if (isNewYearsDay(date)) {
    return {
      type: 'big red',
      name: 'nýársdagur',
    }
  }
  const easterDay = getEasterDay(date.getUTCFullYear())
  if (isSameDay(easterDay, date)) {
    return {
      type: 'big red',
      name: 'páskadagur',
    }
  }
  if (isSameDay(subDays(easterDay, 3), date)) {
    return {
      type: 'red',
      name: 'skírdagur',
    }
  }
  if (isSameDay(subDays(easterDay, 2), date)) {
    return {
      type: 'big red',
      name: 'föstudagurinn langi',
    }
  }
  if (isSameDay(addDays(easterDay, 1), date)) {
    return { type: 'red', name: 'annar í páskum' }
  }
  if (isIcelandicFirstDayOfSummer(date)) {
    return { type: 'red', name: 'sumardagurinn fyrsti' }
  }
  if (isInternationalLabourDay(date)) {
    return { type: 'red', name: 'alþjóðlegur frídagur verkafólks' }
  }
  if (isSameDay(addDays(easterDay, 39), date)) {
    return { type: 'red', name: 'uppstigningardagur' }
  }
  if (isSameDay(addDays(easterDay, 49), date)) {
    return { type: 'big red', name: 'hvítasunnudagur' }
  }
  if (isSameDay(addDays(easterDay, 50), date)) {
    return { type: 'red', name: 'annar í hvítasunnu' }
  }
  if (isIcelandicNationalHoliday(date)) {
    return { type: 'big red', name: 'þjóðhátíðardagur Íslendinga' }
  }
  if (isIcelandicTradesPeopleHoliday(date)) {
    return { type: 'red', name: 'frídagur verslunarmanna' }
  }
  if (date.getMonth() === 11) {
    if (date.getDate() === 24 && date.getFullYear() > 1999) {
      return { type: 'big red', name: 'aðfangadagur' }
    }
    if (date.getDate() === 25) {
      return { type: 'big red', name: 'jóladagur' }
    }
    if (date.getDate() === 26) {
      return { type: 'red', name: 'annar í jólum' }
    }
    if (date.getDate() === 31) {
      return { type: 'big red', name: 'gamlársdagur' }
    }
  }
  return { type: 'normal' }
}

const isNewYearsDay = (date: Date) =>
  date.getUTCMonth() === 0 && date.getUTCDate() === 1

const getEasterDay = (year: number) => {
  const M = 24 // 1900 to 2099
  const N = 5 // 1900 to 2099
  const a = year % 19
  const b = year % 4
  const c = year % 7
  const d = (19 * a + M) % 30
  const e = (2 * b + 4 * c + 6 * d + N) % 7
  const dayMar = 22 + d + e
  const dayApr = d + e - 9
  let day
  let month

  if (dayApr <= 0) {
    day = dayMar
    month = 3
  }

  if (dayMar > 31) {
    day = dayApr
    if (day === 26) {
      day = 19
    }

    if (day === 25 && d === 28 && a > 10) {
      day = 18
    }

    month = 4
  } else {
    day = dayMar
    month = 3
  }

  const date = new Date()
  date.setFullYear(year, month - 1, day)
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  return date
}
const isIcelandicFirstDayOfSummer = (date: Date) =>
  date.getMonth() === 3 &&
  date.getDate() > 18 &&
  date.getDate() < 26 &&
  date.getDay() === 4

const isInternationalLabourDay = (date: Date) =>
  date.getMonth() === 4 && date.getDate() === 1

const isIcelandicNationalHoliday = (date: Date) =>
  date.getMonth() === 5 && date.getDate() === 17

const isIcelandicTradesPeopleHoliday = (date: Date) =>
  date.getMonth() === 7 && date.getDate() < 8 && date.getDay() === 1

export function isWeekendOrHoliday(day: Date, dayType?: IcelandicDayType) {
  return (
    (dayType || getIcelandicDayType(day)).type != 'normal' || isWeekend(day)
  )
}
