import MenuItem from '@material-ui/core/MenuItem'
import * as React from 'react'

import { Customer } from '../../model/customer'
import { SelectField, SelectFieldBaseProps } from '../select-field'

type Props = SelectFieldBaseProps & {
  value: string
  customers: Customer[]
  setCustomer: (value: Customer) => void
  formControlStyle?: React.CSSProperties
}

export function SelectCustomerField({
  customers,
  setCustomer,
  ...rest
}: Props) {
  return (
    <SelectField
      {...rest}
      onChange={(event) => {
        const customer = customers.find((i) => i.id === event.target.value)
        if (customer === undefined) {
          return
        }
        setCustomer(customer)
      }}
    >
      {customers.map((c) => (
        <MenuItem key={c.id} value={c.id}>
          {c.name}
        </MenuItem>
      ))}
    </SelectField>
  )
}
