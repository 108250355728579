import { FirebaseError } from 'firebase/app'

export function getAuthErrorMessage(error: FirebaseError) {
  switch (error.code) {
    case 'auth/invalid-action-code':
      return 'Þessi innskráningar tengill er ógildur. Það getur gerst þegar hann er brenglaður, útrunnin eða hefur verið notaður.'
    case 'auth/invalid-email':
      return 'Þetta póstfang er ekki það sama og póstfangið sem þessi innskráningar tengill var sendur á.'
    default:
      return 'Óþekkt innskráningar villa.'
  }
}
