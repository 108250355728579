import addDays from 'date-fns/addDays'
import max from 'date-fns/max'
import { Timestamp } from 'firebase/firestore'
import { CareReceiverStatus } from '../../../model/care-receiver-status'
import { isDurationStatus } from '../../../model/care-receiver-status/is-duration-status'

/**
 * Immutably set's status, and any other statuses chained off of it
 * until date to at least the minDate and so on.
 */
export function setStatusMinDate(
  status: CareReceiverStatus,
  minDate: Date
): CareReceiverStatus {
  if (!isDurationStatus(status) || status.duration.type !== 'until') {
    return status
  }
  const until = max([minDate, status.duration.until.toDate()])
  return {
    ...status,
    duration: {
      ...status.duration,
      until: Timestamp.fromDate(until),
      next: setStatusMinDate(status.duration.next, addDays(until, 1)),
    },
  }
}
