import { orderBy, QueryConstraint } from 'firebase/firestore'
import { Collections } from '../collections'
import { useCollection } from '../utils/use-collection'
import { parseDaySnapsnot } from './parse-day-snapshot'

export function useDays(
  queryConstraints: QueryConstraint[] = [],
  deps: React.DependencyList = []
) {
  return useCollection({
    path: Collections.Days,
    parseDocument: parseDaySnapsnot,
    queryConstraints: [...queryConstraints, orderBy('day', 'asc')],
    deps,
  })
}
