import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import { useUUID } from 'ignitic/dist/hooks/use-uuid'
import * as React from 'react'

export type SelectFieldBaseProps = {
  label: string
  required?: boolean
  onBlur?: () => void
  onFocus?: () => void
  error?: boolean
  helperText?: string
}

type Props = SelectFieldBaseProps & {
  value: string | string[]
  multiple?: boolean
  children: React.ReactNode
  onChange?:
    | ((
        event: React.ChangeEvent<HTMLSelectElement>,
        child: React.ReactNode
      ) => void)
    | undefined
  renderValue?: (
    value:
      | string
      | number
      | boolean
      | object
      | (string | number | boolean | object)[]
      | undefined
  ) => React.ReactNode
  formControlStyle?: React.CSSProperties
}

export function SelectField(props: Props) {
  const id = useUUID()
  return (
    <FormControl
      required={props.required}
      error={props.error}
      style={props.formControlStyle}
    >
      <InputLabel htmlFor={id}>{props.label}</InputLabel>
      <Select
        multiple={props.multiple}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        value={props.value}
        inputProps={{ id }}
        onChange={props.onChange}
        renderValue={props.renderValue}
      >
        {props.children}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  )
}
