import { isBefore } from 'date-fns'

import { CareReceiverStatus } from '.'

export function getCareReceiverStatusForDay(
  currentStatus: CareReceiverStatus,
  day: Date
): CareReceiverStatus {
  switch (currentStatus.type) {
    case 'unknown':
    case 'quit':
    case 'deceased':
      return currentStatus
    case 'hospital':
    case 'bed rest admission':
    case 'on pause':
    case 'repeat': {
      if (
        currentStatus.duration.type === 'indefinite' ||
        !isBefore(currentStatus.duration.until.toDate(), day)
      ) {
        return currentStatus
      } else {
        return getCareReceiverStatusForDay(currentStatus.duration.next, day)
      }
    }
    default:
      const never: never = currentStatus
      return never
  }
}
