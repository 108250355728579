div.highlight {
  background: #3f51b5;
  color: white;
}
span.highlight {
  color: white;
}
.firstHighlight {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.endHighlight {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
.day {
  width: 36px;
  height: 36px;
  font-size: 12px;
  margin: 0 2px;
  color: 'inherit';
}
.nonCurrentMonthDay {
  color: rgba(0, 0, 0, 0.26);
}
.highlightNonCurrentMonthDay {
  color: #676767;
}
