export function immutablePush<T>(arr: T[], item: T): T[] {
  return [...arr, item]
}

export function immutableUpdateItem<T>(arr: T[], update: T, index: number) {
  return [...arr.slice(0, index), update, ...arr.slice(index + 1)]
}

export function immutableInsertItem<T>(arr: T[], item: T, atIndex: number) {
  return [...arr.slice(0, atIndex), item, ...arr.slice(atIndex)]
}

export function immutableRemoveItem<T>(arr: T[], index: number) {
  return [...arr.slice(0, index), ...arr.slice(index + 1)]
}

export function immutableSort<T>(
  arr: T[],
  compareFn?: ((a: T, b: T) => number) | undefined
) {
  return [...arr].sort(compareFn)
}

export function immutableReorder<T>(list: T[], from: number, to: number) {
  const result = Array.from(list)
  const [removed] = result.splice(from, 1)
  result.splice(to, 0, removed)

  return result
}

export function localCompareFn(a: string, b: string) {
  return a.localeCompare(b)
}

export function filterUndefined<T>(ts: (T | undefined)[]): T[] {
  return ts.filter((t: T | undefined): t is T => !!t)
}

export function chunks<T>(arr: T[], maxChunkSize: number): T[][] {
  const ratio = arr.length / maxChunkSize
  if (ratio <= 0) {
    return [arr]
  }
  const sizes: number[] = Array(Math.floor(ratio)).fill(maxChunkSize)
  const lastSize = arr.length % maxChunkSize
  if (lastSize !== 0) {
    sizes.push(lastSize)
  }
  const result: T[][] = []
  sizes.forEach((_value, index) => {
    result.push(arr.slice(index * maxChunkSize, (index + 1) * maxChunkSize))
  })
  return result
}
