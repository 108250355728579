import { assertNever } from 'itils/dist/ts/assert-never'

import { CareReceiverStatus } from '.'

export function careReceiverStatusToText(status: CareReceiverStatus): string {
  if (status.type == 'repeat') {
    const portions = status.repitition.portions
    const allDays = Object.values(portions)
    const workDays = [
      portions.mon,
      portions.tue,
      portions.wed,
      portions.thu,
      portions.fri,
    ]
    const weekend = [portions.sat, portions.sun]
    if (allDays.every((i) => i > 0)) {
      return 'alla daga'
    }
    if (workDays.every((i) => i == 0) && weekend.every((i) => i > 0)) {
      return 'helgar'
    }
    if (weekend.every((i) => i == 0) && workDays.every((i) => i > 0)) {
      return 'virka daga'
    }
    if (allDays.some((i) => i > 0)) {
      return `${[
        portions.mon > 0 ? 'mán' : '',
        portions.tue > 0 ? 'þri' : '',
        portions.wed > 0 ? 'mið' : '',
        portions.thu > 0 ? 'fim' : '',
        portions.fri > 0 ? 'fös' : '',
      ]
        .filter((i) => i != '')
        .join(', ')}${
        weekend.every((i) => i > 0)
          ? ' og helgar'
          : (workDays.every((i) => i == 0) || weekend.every((i) => i == 0)
              ? ''
              : ', ') +
            [portions.sat > 0 ? 'lau' : '', portions.sun > 0 ? 'sun' : '']
              .filter((i) => i != '')
              .join(', ')
      }`
    }
  }
  return careReceiverStatusTypeToText(status.type)
}

export function careReceiverStatusTypeToText(
  value: CareReceiverStatus['type']
): string {
  switch (value) {
    case 'bed rest admission':
      return 'hvíldarinnlögn'
    case 'deceased':
      return 'er látin/n'
    case 'hospital':
      return 'á spítala'
    case 'on pause':
      return 'í pásu'
    case 'quit':
      return 'er hætt/ur'
    case 'repeat': {
      return 'virk/ur'
    }
    case 'unknown':
      return 'óþekkt'
    default:
      assertNever(value)
      return ''
  }
}
