import { CareDayStatus } from './day'

export function careDayStatusToText(status: CareDayStatus) {
  switch (status.type) {
    case 'processed':
      return 'Dagur afgreiddur'
    case 'open':
      return 'Opið fyrir pantanir'
    case 'orders closed':
      return 'Lokað fyrir pantanir'
    default:
      return ''
  }
}
