import { isKennitala } from '../../utils/isKennitala'

export function getKennitalaFieldValidity(
  kennitala: string,
  existingKennitalas: string[] = []
) {
  const kennitalaExists = !existingKennitalas.every((i) => i != kennitala)
  return {
    valid: isKennitala(kennitala) && !kennitalaExists,
    text:
      kennitala === ''
        ? 'Vantar kennitölu'
        : !isKennitala(kennitala)
        ? 'Ógild kennitala'
        : kennitalaExists
        ? 'Kennitala þegar skráð'
        : ' ',
  }
}

export function getDeliveryLocFieldValidity(deliveryLocation: string) {
  return {
    valid: deliveryLocation.length >= 3,
    text:
      deliveryLocation.length == 0
        ? 'Vantar heimilisfang'
        : deliveryLocation.length < 3
        ? 'Heimilisfang a.m.k. 3 bókstafir'
        : ' ',
  }
}

export function getNameFieldValidity(name: string) {
  return {
    valid: name.length >= 2,
    text:
      name.length == 0
        ? 'Vantar nafn'
        : name.length < 2
        ? 'Nafn a.m.k. 2 bókstafir'
        : ' ',
  }
}

export function isValid(values: { valid: boolean }[]) {
  return values.every((i) => i.valid)
}
