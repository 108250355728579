.changelogList {
  padding: 0;
  margin: 0;
}
.changelogList > .changelogItem + .changelogItem {
  border-top: 1px solid var(--default-on-background-color);
}
.changelogItem {
  padding-top: 1rem;
}
.changelogItem ul {
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.changelogItem ul li + li {
  margin-top: 1rem;
}
