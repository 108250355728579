import { useState } from 'react'

import { DateInterval } from '../model/types/interval'
import { endOfWeekIs, startOfWeekIs } from '../utils/date-fns'

export function useWeekIs(initial: Date): [DateInterval, (d: Date) => void] {
  const [interval, setInterval] = useState<DateInterval>({
    from: startOfWeekIs(initial),
    to: endOfWeekIs(initial),
  })
  return [
    interval,
    (d: Date) =>
      setInterval({
        from: startOfWeekIs(d),
        to: endOfWeekIs(d),
      }),
  ]
}
