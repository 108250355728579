import { CareReceiverStatus } from '.'

export const defaultRepeatStatus: CareReceiverStatus = {
  type: 'repeat',
  repitition: {
    type: 'weekly',
    portions: {
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      sat: 0,
      sun: 0,
    },
  },
  redDays: { type: 'yes' },
  duration: {
    type: 'indefinite',
  },
}
export const defaultHospitalStatus: CareReceiverStatus = {
  type: 'hospital',
  duration: { type: 'indefinite' },
}
export const defaultBedRestAdmissionStatus: CareReceiverStatus = {
  type: 'bed rest admission',
  duration: { type: 'indefinite' },
}
export const defaultOnPauseStatus: CareReceiverStatus = {
  type: 'on pause',
  duration: { type: 'indefinite' },
}
export const defaultUnknownStatus: CareReceiverStatus = {
  type: 'unknown',
}
export const defaultQuitStatus: CareReceiverStatus = {
  type: 'quit',
}
export const defaultDeceacedStatus: CareReceiverStatus = {
  type: 'deceased',
}
