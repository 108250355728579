import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import { User } from 'firebase/auth'
import { Button } from 'ignitic/dist/core/button'
import { useUUID } from 'ignitic/dist/hooks/use-uuid'
import { display } from 'ignitic/dist/styles/display'
import { flex } from 'ignitic/dist/styles/flex'
import { cn } from 'itils/dist/misc/cn'
import * as React from 'react'
import { updateCareReceiver } from '../../firebase/firestore/care-receivers/update-care-receiver'
import { useTextInput } from '../../hooks/useTextInput'
import { CareConfig } from '../../model/care-config'
import { CareReceiver } from '../../model/care-receiver'
import { SelectMultipleStringsField } from '../select-multiple-strings-field'
import { SelectStringField } from '../select-string-field'
import {
  getDeliveryLocFieldValidity,
  getKennitalaFieldValidity,
  getNameFieldValidity,
  isValid,
} from './care-receiver-form-utils'

type UpdateReceiverFormBlurStatus = {
  name: boolean
  deliveryLocation: boolean
  kennitala: boolean
}
const noneBlurred: UpdateReceiverFormBlurStatus = {
  name: false,
  deliveryLocation: false,
  kennitala: false,
}
const allBlurred: UpdateReceiverFormBlurStatus = {
  name: true,
  deliveryLocation: true,
  kennitala: true,
}

export type UpdateCareReceiverGeneralDialogOpen =
  | {
      type: 'open'
      receiver: CareReceiver
    }
  | { type: 'closed' }

type Props = {
  open: UpdateCareReceiverGeneralDialogOpen
  onClose: () => void
  id: string
  careConfig: CareConfig
  user: User
  /**
   * All care receivers of the current customer
   */
  careReceivers: CareReceiver[]
}

export function UpdateCareReceiverGeneralDialog({
  open,
  onClose,
  id,
  careConfig,
  user,
  careReceivers,
}: Props) {
  const titleId = useUUID()
  const [name, setName, nameInputProps] = useTextInput('')
  const [kennitala, setKennitala, kennitalaInputProps] = useTextInput('')
  const [deliveryLocation, setDeliveryLocation, deliveryLocationInputProps] =
    useTextInput('')
  const [customerNote, setCustomerNote, customerNoteInputProps] =
    useTextInput('')
  const [phone1, setPhone1, phone1InputProps] = useTextInput('')
  const [phone2, setPhone2, phone2InputProps] = useTextInput('')
  const [relativePhone, setRelativePhone, relativePhoneInputProps] =
    useTextInput('')
  const [foodType, setFoodType] = React.useState('')
  const [foodRestrictions, setFoodRestrictions] = React.useState<string[]>([])
  const [blurred, setBlurred] = React.useState(noneBlurred)

  const nameFieldValidity = getNameFieldValidity(name)
  const kennitalaFieldValidity = getKennitalaFieldValidity(
    kennitala,
    careReceivers
      .filter((i) =>
        open.type == 'open' ? i.kennitala !== open.receiver.kennitala : false
      )
      .map((i) => i.kennitala)
  )
  const deliveryLocFieldValidity = getDeliveryLocFieldValidity(deliveryLocation)
  const isInputValid = isValid([
    nameFieldValidity,
    kennitalaFieldValidity,
    deliveryLocFieldValidity,
  ])
  const submitDisabled =
    (open.type == 'open' &&
      [
        open.receiver.name == name,
        open.receiver.kennitala == kennitala,
        open.receiver.deliveryLocation == deliveryLocation,
        open.receiver.customerNote == customerNote,
        (open.receiver.phone1 || '') == phone1,
        (open.receiver.phone2 || '') == phone2,
        (open.receiver.relativePhone || '') == relativePhone,
        open.receiver.foodType == foodType,
        open.receiver.foodRestrictions == foodRestrictions,
      ].every((i) => i)) ||
    !isInputValid

  React.useEffect(() => {
    if (open.type == 'open') {
      setName(open.receiver.name)
      setKennitala(open.receiver.kennitala)
      setDeliveryLocation(open.receiver.deliveryLocation)
      setCustomerNote(open.receiver.customerNote)
      setPhone1(open.receiver.phone1 || '')
      setPhone2(open.receiver.phone2 || '')
      setRelativePhone(open.receiver.relativePhone || '')
      setFoodType(open.receiver.foodType)
      setFoodRestrictions(open.receiver.foodRestrictions)
    }
  }, [open])

  function closeDialog() {
    onClose()
    resetState()
  }

  function resetState() {
    setName('')
    setKennitala('')
    setDeliveryLocation('')
    setCustomerNote('')
    setPhone1('')
    setPhone2('')
    setRelativePhone('')
    setFoodType('')
    setFoodRestrictions([])
    setBlurred(noneBlurred)
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (submitDisabled || open.type == 'closed') {
      return
    }
    updateCareReceiver({
      id: open.receiver.id,
      update: {
        name,
        kennitala,
        deliveryLocation,
        customerNote,
        phone1,
        phone2,
        relativePhone,
        foodType,
        foodRestrictions,
      },
      user,
    }).catch((err: any) => {
      // ToDo:
      // - Show some notification
      // - Add logging
      console.error('updateCareReceiver error', err)
    })
    onClose()
  }
  return (
    <Dialog
      open={open.type == 'open'}
      onClose={closeDialog}
      id={id}
      aria-labelledby={titleId}
    >
      {open.type == 'open' && (
        <React.Fragment>
          <DialogTitle style={{ paddingBottom: 0 }} id={titleId}>
            Uppfæra grunnupplýsingar {open.receiver.name}
          </DialogTitle>
          <form noValidate autoComplete="off" onSubmit={onSubmit}>
            <DialogContent
              className={cn(display.flex, flex.col, flex.spacingSm)}
            >
              <TextField
                label="Nafn"
                required
                autoFocus
                {...nameInputProps}
                error={blurred.name && !nameFieldValidity.valid}
                onBlur={() => setBlurred({ ...blurred, name: true })}
                onFocus={() => setBlurred({ ...blurred, name: false })}
                helperText={blurred.name ? nameFieldValidity.text : ' '}
              />
              <TextField
                label="Heimilisfang"
                required
                {...deliveryLocationInputProps}
                error={
                  blurred.deliveryLocation && !deliveryLocFieldValidity.valid
                }
                onBlur={() =>
                  setBlurred({ ...blurred, deliveryLocation: true })
                }
                onFocus={() =>
                  setBlurred({ ...blurred, deliveryLocation: false })
                }
                helperText={
                  blurred.deliveryLocation ? deliveryLocFieldValidity.text : ' '
                }
              />
              <TextField
                label="Kennitala"
                required
                {...kennitalaInputProps}
                error={blurred.kennitala && !kennitalaFieldValidity.valid}
                onBlur={() => setBlurred({ ...blurred, kennitala: true })}
                onFocus={() => setBlurred({ ...blurred, kennitala: false })}
                helperText={
                  blurred.kennitala ? kennitalaFieldValidity.text : ' '
                }
              />
              <TextField
                label="Athugasemd"
                {...customerNoteInputProps}
                helperText=" "
              />
              <SelectStringField
                label="Tegund"
                required
                value={foodType}
                values={careConfig.foodTypes}
                onChange={setFoodType}
              />
              <SelectMultipleStringsField
                label="Sérþarfir"
                value={foodRestrictions}
                values={careConfig.foodRestrictions}
                onChange={(values) => setFoodRestrictions(values)}
                onDelete={(value) =>
                  setFoodRestrictions(
                    foodRestrictions.filter((r) => r !== value)
                  )
                }
              />
              <TextField
                label="Símanúmer 1"
                {...phone1InputProps}
                helperText=" "
              />
              <TextField
                label="Símanúmer 2"
                {...phone2InputProps}
                helperText=" "
              />
              <TextField
                label="Símanúmer aðstandanda"
                {...relativePhoneInputProps}
                helperText=" "
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={closeDialog}
                type="button"
                variant="outlined"
                color="secondary"
              >
                Hætta við
              </Button>
              <div onClick={() => setBlurred(allBlurred)}>
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  disabled={submitDisabled}
                >
                  Vista
                </Button>
              </div>
            </DialogActions>
          </form>
        </React.Fragment>
      )}
    </Dialog>
  )
}
