import { User } from 'firebase/auth'
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { firestore } from '../..'
import { UpdateCareOrderDocument } from '../../../model/care-order'
import { Omit } from '../../../utils/type'
import { Collections } from '../collections'

type UpdateCareOrderArg = {
  id: string
  update: Omit<UpdateCareOrderDocument, 'lastModified'>
  user: User
}

export function updateCareOrder({ id, update, user }: UpdateCareOrderArg) {
  const updateWitLastMod: UpdateCareOrderDocument = {
    ...update,
    lastModified: {
      timestamp: serverTimestamp() as any,
      by: {
        type: 'user',
        uid: user.uid,
        email: user.email as string,
      },
    },
  }
  return updateDoc(doc(firestore, Collections.CareOrders, id), updateWitLastMod)
}
